import React, { useContext, useState, useEffect } from "react";
import AuthContext from "../../../context/auth/authContext";
import alertContext from "../../../context/alert/alertContext";
import {
  Container,
  Backdrop,
  CircularProgress,
  Grid,
  Button,
  TextField,
  Card,
  CardContent,
  IconButton,
  Typography,
  Tooltip,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import axios from "axios";
import NewAlert from "../../../components/alert/NewAlert";
import SaveIcon from "@mui/icons-material/Save";
import Edit from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";

const EditPaymentMethod = ({
  focusedMethod,
  setFocusedMethod,
  setLoadedPage,
}) => {
  const { setAlert } = useContext(alertContext);
  const { user } = useContext(AuthContext);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [notEditable, setNotEditable] = useState(true);
  const [paymentInfo, setPaymentInfo] = useState({
    company: "",
    firstName: "",
    lastName: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    cardNumber: "",
    expirationDateA: "XX",
    expirationDateB: "XXXX",
    cardCode: "",
    cardType: "",
  });

  const [errors, setErrors] = useState({
    company: false,
    firstName: false,
    lastName: false,
    address: false,
    city: false,
    state: false,
    zip: false,
    cardNumber: false,
    expirationDateA: false,
    expirationDateB: false,
    cardCode: false,
  });

  const [cardTypeCheckboxes, setCardTypeCheckboxes] = useState({
    visa: false,
    mc: false,
    amex: false,
    discover: false,
  });

  const helperText = {
    company: "Please enter the Company Name",
    firstName: "First Name is Manditory",
    lastName: "Last Name is Manditory",
    address: "Please enter the billing address for the credit card",
    city: "Please enter the city",
    state: "Please enter your state",
    zip: "Please enter your 7 digit zip code",
    cardNumber: "Credit Card number is required",
    expirationDateA: "Enter Month (MM)",
    expirationDateB: "Enter Year (YYYY)",
    cardCode: "Please enter the security code",
  };

  useEffect(() => {
    setPaymentInfo({
      company: focusedMethod.billTo.company,
      firstName: focusedMethod.billTo.firstName,
      lastName: focusedMethod.billTo.lastName,
      address: focusedMethod.billTo.address,
      city: focusedMethod.billTo.city,
      state: focusedMethod.billTo.state,
      zip: focusedMethod.billTo.zip,
      cardNumber: focusedMethod.payment.creditCard.cardNumber,
      expirationDateA: "XX",
      expirationDateB: "XXXX",
      cardCode: "",
      cardType: focusedMethod.payment.creditCard.cardType,
    });
    focusedMethod.payment.creditCard.cardType === "Visa" &&
      setCardTypeCheckboxes({ ...cardTypeCheckboxes, visa: true });
    focusedMethod.payment.creditCard.cardType === "MasterCard" &&
      setCardTypeCheckboxes({ ...cardTypeCheckboxes, mc: true });
    focusedMethod.payment.creditCard.cardType === "AmericanExpress" &&
      setCardTypeCheckboxes({ ...cardTypeCheckboxes, amex: true });
    focusedMethod.payment.creditCard.cardType === "Discover" &&
      setCardTypeCheckboxes({ ...cardTypeCheckboxes, discover: true });

    //eslint-disable-next-line
  }, [focusedMethod]);

  const formEntry = (e) => {
    setPaymentInfo({ ...paymentInfo, [e.target.id]: e.target.value });
  };

  const toggleEdit = () => {
    setNotEditable(!notEditable);
  };

  const checkBox = (e) => {
    if (e.target.name === "visa") {
      if (cardTypeCheckboxes.visa === false) {
        setCardTypeCheckboxes({
          visa: true,
          mc: false,
          amex: false,
          discover: false,
        });
        setPaymentInfo({ ...paymentInfo, cardType: "visa" });
      } else {
        setCardTypeCheckboxes({
          visa: false,
          mc: false,
          amex: false,
          discover: false,
        });
        setPaymentInfo({ ...paymentInfo, cardType: "" });
      }
    }
    if (e.target.name === "mc") {
      if (cardTypeCheckboxes.mc === false) {
        setCardTypeCheckboxes({
          visa: false,
          mc: true,
          amex: false,
          discover: false,
        });
        setPaymentInfo({ ...paymentInfo, cardType: "mc" });
      } else {
        setCardTypeCheckboxes({
          visa: false,
          mc: false,
          amex: false,
          discover: false,
        });
        setPaymentInfo({ ...paymentInfo, cardType: "" });
      }
    }
    if (e.target.name === "amex") {
      if (cardTypeCheckboxes.amex === false) {
        setCardTypeCheckboxes({
          visa: false,
          mc: false,
          amex: true,
          discover: false,
        });
        setPaymentInfo({ ...paymentInfo, cardType: "amex" });
      } else {
        setCardTypeCheckboxes({
          visa: false,
          mc: false,
          amex: false,
          discover: false,
        });
        setPaymentInfo({ ...paymentInfo, cardType: "" });
      }
    }
    if (e.target.name === "discover") {
      if (cardTypeCheckboxes.discover === false) {
        setCardTypeCheckboxes({
          visa: false,
          mc: false,
          amex: false,
          discover: true,
        });
        setPaymentInfo({ ...paymentInfo, cardType: "discover" });
      } else {
        setCardTypeCheckboxes({
          visa: false,
          mc: false,
          amex: false,
          discover: false,
        });
        setPaymentInfo({ ...paymentInfo, cardType: "" });
      }
    }
  };

  const resetForm = () => {
    setPaymentInfo({
      company: focusedMethod.billTo.company,
      firstName: focusedMethod.billTo.firstName,
      lastName: focusedMethod.billTo.lastName,
      address: focusedMethod.billTo.address,
      city: focusedMethod.billTo.city,
      state: focusedMethod.billTo.state,
      zip: focusedMethod.billTo.zip,
      cardNumber: focusedMethod.payment.creditCard.cardNumber,
      expirationDateA: "XX",
      expirationDateB: "XXXX",
      cardCode: "",
      cardType: focusedMethod.payment.creditCard.cardType,
    });
    setErrors({
      company: false,
      firstName: false,
      lastName: false,
      address: false,
      city: false,
      state: false,
      zip: false,
      cardNumber: false,
      expirationDateA: false,
      expirationDateB: false,
      cardCode: false,
    });

    var cardCheckboxes = {
      visa: false,
      mc: false,
      amex: false,
      discover: false,
    };
    if (focusedMethod.payment.creditCard.cardType === "Visa") {
      cardCheckboxes.visa = true;
    }
    if (focusedMethod.payment.creditCard.cardType === "MasterCard") {
      cardCheckboxes.mc = true;
    }
    if (focusedMethod.payment.creditCard.cardType === "AmericanExpress") {
      cardCheckboxes.amex = true;
    }
    if (focusedMethod.payment.creditCard.cardType === "Discover") {
      cardCheckboxes.discover = true;
    }

    setCardTypeCheckboxes(cardCheckboxes);
  };

  const validation = () => {
    var validated = { ...errors };

    if (paymentInfo.company === "") {
      validated.company = true;
    } else {
      validated.company = false;
    }
    if (paymentInfo.firstName === "") {
      validated.firstName = true;
    } else {
      validated.firstName = false;
    }
    if (paymentInfo.lastName === "") {
      validated.lastName = true;
    } else {
      validated.lastName = false;
    }
    if (paymentInfo.address === "") {
      validated.address = true;
    } else {
      validated.address = false;
    }
    if (paymentInfo.city === "") {
      validated.city = true;
    } else {
      validated.city = false;
    }
    if (paymentInfo.state === "") {
      validated.state = true;
    } else {
      validated.state = false;
    }
    if (paymentInfo.zip.length !== 5) {
      validated.zip = true;
    } else {
      validated.zip = false;
    }
    if (paymentInfo.cardNumber.length === 0) {
      validated.cardNumber = true;
    } else {
      validated.cardNumber = false;
    }
    if (paymentInfo.expirationDateA.length !== 2) {
      validated.expirationDateA = true;
    } else {
      validated.expirationDateA = false;
    }
    if (paymentInfo.expirationDateB.length !== 4) {
      validated.expirationDateB = true;
    } else {
      validated.expirationDateB = false;
    }

    setErrors(validated);
    return Object.values(validated).every((x) => x === false);
  };

  const editPaymentMethod = async () => {
    const results = validation();
    window.scroll(0, 0);
    var expirationDate = "";
    if (results) {
      if (paymentInfo.expirationDateA !== "XX") {
        expirationDate =
          paymentInfo.expirationDateB + "-" + paymentInfo.expirationDateA;
      } else {
        expirationDate = focusedMethod.payment.creditCard.expirationDate;
      }
      const submissionData = {
        _id: user._id,
        authProfileId: user.authProfileId,
        customerType: "business",
        firstName: paymentInfo.firstName,
        lastName: paymentInfo.lastName,
        company: paymentInfo.company,
        address: paymentInfo.address,
        city: paymentInfo.city,
        state: paymentInfo.state,
        zip: paymentInfo.zip,
        cardNumber: paymentInfo.cardNumber,
        expirationDate: expirationDate,
        cardCode: paymentInfo.cardCode,
        customerPaymentProfileId: focusedMethod.customerPaymentProfileId,
      };
      setShowBackdrop(true);
      const response = await axios.post(
        "/api/paymentProfile/edit/",
        submissionData
      );
      if (response.data === "Success") {
        resetForm();
        setShowBackdrop(false);
        setAlert(true, "Updated Successfully", "success");
        setLoadedPage("list");
      } else if (response.data === "Duplicate") {
        setShowBackdrop(false);
        setAlert(
          true,
          "This is a Duplicate Entry. Edit or Remove Existing",
          "error"
        );
      } else if (response.data === "Invalid Card Number") {
        setShowBackdrop(false);
        setAlert(true, "Invalid Card Number", "error");
      }
    } else {
      setAlert(true, "Please resolve errors!", "error");
    }
  };

  return (
    <Container>
      {showBackdrop && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={showBackdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      <Card elevation={4} sx={{ mt: 3, p: { xs: 1, sm: 2 } }}>
        <CardContent>
          <Grid container>
            <Grid item xs={1}>
              {notEditable ? (
                <Tooltip title="Return to list">
                  <IconButton onClick={() => setLoadedPage("list")}>
                    <CancelIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Cancel Editing">
                  <IconButton onClick={toggleEdit}>
                    <CancelIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Grid>
            <Grid item xs={10}>
              <Typography
                variant="h5"
                sx={{
                  textAlign: "center",
                  fontSize: { xs: "1.35rem", sm: "1.5rem" },
                }}
                color="primary"
              >
                Payment Method Details
              </Typography>
            </Grid>
            <Grid item xs={1}>
              {notEditable ? (
                <Tooltip title="Edit">
                  <IconButton sx={{ float: "right" }} onClick={toggleEdit}>
                    <Edit sx={{ float: "right" }} />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Save Changes">
                  <IconButton
                    sx={{ float: "right" }}
                    onClick={editPaymentMethod}
                  >
                    <SaveIcon sx={{ float: "right" }} />
                  </IconButton>
                </Tooltip>
              )}
            </Grid>
          </Grid>

          <Grid container sx={{ mt: 3 }}>
            <Grid item xs={12} sm={6}>
              <Card
                sx={{
                  padding: { xs: "16px", sm: "24px 32px 48px 32px" },
                  maxWidth: { xs: "100%", sm: "90%" },
                }}
                elevation={2}
              >
                <Grid container sx={{ mt: 1 }}>
                  <Grid item xs={12}>
                    <TextField
                      variant="standard"
                      label="Company"
                      disabled={notEditable}
                      id="company"
                      onChange={formEntry}
                      value={paymentInfo.company}
                      sx={{ minWidth: "100%" }}
                      error={errors.company}
                      helperText={errors.company && helperText.company}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="standard"
                      label="First Name"
                      id="firstName"
                      disabled={notEditable}
                      onChange={formEntry}
                      value={paymentInfo.firstName}
                      sx={{ minWidth: "99%" }}
                      error={errors.firstName}
                      helperText={errors.firstName && helperText.firstName}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="standard"
                      label="Last Name"
                      id="lastName"
                      disabled={notEditable}
                      onChange={formEntry}
                      value={paymentInfo.lastName}
                      sx={{ minWidth: "100%" }}
                      error={errors.lastName}
                      helperText={errors.lastName && helperText.lastName}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="standard"
                      label="Address"
                      id="address"
                      disabled={notEditable}
                      onChange={formEntry}
                      value={paymentInfo.address}
                      sx={{ minWidth: "100%" }}
                      error={errors.address}
                      helperText={errors.address && helperText.address}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="standard"
                      label="City"
                      id="city"
                      disabled={notEditable}
                      onChange={formEntry}
                      value={paymentInfo.city}
                      sx={{ minWidth: "100%" }}
                      error={errors.city}
                      helperText={errors.city && helperText.city}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="standard"
                      label="State"
                      id="state"
                      disabled={notEditable}
                      onChange={formEntry}
                      value={paymentInfo.state}
                      sx={{ minWidth: "100%" }}
                      error={errors.state}
                      helperText={errors.state && helperText.state}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="standard"
                      label="Zip"
                      id="zip"
                      disabled={notEditable}
                      onChange={formEntry}
                      value={paymentInfo.zip}
                      sx={{ minWidth: "100%" }}
                      error={errors.zip}
                      helperText={errors.zip && helperText.zip}
                    />
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Card
                sx={{ p: 3, maxWidth: "100%", mt: { xs: 2, sm: 0 } }}
                elevation={2}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <FormGroup sx={{ flexDirection: "row" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            sx={{ ml: 3 }}
                            id="cardType"
                            disabled={notEditable}
                            name="visa"
                            onChange={checkBox}
                            checked={cardTypeCheckboxes.visa}
                          />
                        }
                        label="VISA"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            sx={{ ml: 3 }}
                            id="cardType"
                            disabled={notEditable}
                            name="mc"
                            onChange={checkBox}
                            checked={cardTypeCheckboxes.mc}
                          />
                        }
                        label="MC"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            sx={{ ml: 3 }}
                            id="cardType"
                            disabled={notEditable}
                            name="amex"
                            onChange={checkBox}
                            checked={cardTypeCheckboxes.amex}
                          />
                        }
                        label="AMEX"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            sx={{ ml: 3 }}
                            id="cardType"
                            disabled={notEditable}
                            name="discover"
                            onChange={checkBox}
                            checked={cardTypeCheckboxes.discover}
                          />
                        }
                        label="DISCOVER"
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="standard"
                      label="Card Number"
                      disabled={notEditable}
                      id="cardNumber"
                      onChange={formEntry}
                      value={paymentInfo.cardNumber}
                      sx={{ minWidth: "100%" }}
                      error={errors.cardNumber}
                      helperText={errors.cardNumber && helperText.cardNumber}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      variant="standard"
                      label="MM"
                      disabled={notEditable}
                      id="expirationDateA"
                      onChange={formEntry}
                      value={paymentInfo.expirationDateA}
                      sx={{ minWidth: "90%" }}
                      error={errors.expirationDateA}
                      helperText={
                        errors.expirationDateA && helperText.expirationDateA
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      variant="standard"
                      label="YYYY"
                      disabled={notEditable}
                      id="expirationDateB"
                      onChange={formEntry}
                      value={paymentInfo.expirationDateB}
                      sx={{ minWidth: "100%" }}
                      error={errors.expirationDateB}
                      helperText={
                        errors.expirationDateB && helperText.expirationDateB
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="standard"
                      label="CVV"
                      disabled={notEditable}
                      id="cardCode"
                      onChange={formEntry}
                      value={paymentInfo.cardCode}
                      sx={{ minWidth: "100%" }}
                      error={errors.cardCode}
                      helperText={errors.cardCode && helperText.cardCode}
                    />
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>

          <Grid container sx={{ mt: 3 }}>
            <Grid item xs={12}>
              {!notEditable && (
                <Button variant="contained" color="error" onClick={resetForm}>
                  RESET
                </Button>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <NewAlert />
    </Container>
  );
};

export default EditPaymentMethod;

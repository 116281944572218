import React from "react";
import {
  Container,
  Paper,
  Card,
  CardContent,
  Typography,
  Grid,
} from "@mui/material";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import PaymentsIcon from "@mui/icons-material/Payments";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AccessAlarmsIcon from "@mui/icons-material/AccessAlarms";

const PortalNavigation = ({ setPortalPage }) => {
  const navigateTo = (page) => {
    setPortalPage(page);
  };
  return (
    <Container>
      <Typography
        variant="h4"
        color="primary"
        sx={{ textAlign: "center", mt: 3 }}
      >
        Bill Pay Portal
      </Typography>
      <Paper elevation={6} sx={{ mt: 5, pt: 3, pr: 3, pl: 3, pb: 6 }}>
        <Grid container sx={{ mt: 4 }}>
          <Grid
            item
            xs={6}
            sm={3}
            sx={{
              display: { sm: "flex" },
              flexDirection: { sm: "column" },
              alignItems: { sm: "center" },
            }}
          >
            <Card
              elevation={4}
              sx={{
                minHeight: "150px",
                mr: { xs: 2, sm: 0 },
                width: { xs: "auto", sm: "150px" },
                cursor: "pointer",
              }}
              onClick={() => navigateTo("payment")}
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <PaymentsIcon
                  color="primary"
                  sx={{ mx: "auto" }}
                  fontSize="large"
                />
                <Typography
                  variant="body1"
                  color="primary"
                  sx={{ mt: 2, fontWeight: "bold" }}
                >
                  Make
                </Typography>
                <Typography
                  variant="body1"
                  color="primary"
                  sx={{ fontWeight: "bold" }}
                >
                  Payment
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            item
            xs={6}
            sm={3}
            sx={{
              display: { sm: "flex" },
              flexDirection: { sm: "column" },
              alignItems: { sm: "center" },
            }}
          >
            <Card
              elevation={4}
              sx={{
                minHeight: "150px",
                ml: { xs: 2, sm: 0 },
                width: { xs: "auto", sm: "150px" },
                cursor: "pointer",
              }}
              onClick={() => navigateTo("methods")}
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <CreditCardIcon
                  color="primary"
                  sx={{ mx: "auto" }}
                  fontSize="large"
                />
                <Typography
                  variant="body1"
                  color="primary"
                  sx={{ mt: 2, fontWeight: "bold" }}
                >
                  Payment
                </Typography>
                <Typography
                  variant="body1"
                  color="primary"
                  sx={{ fontWeight: "bold" }}
                >
                  Methods
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            item
            xs={6}
            sm={3}
            sx={{
              display: { sm: "flex" },
              flexDirection: { sm: "column" },
              alignItems: { sm: "center" },
            }}
          >
            <Card
              elevation={4}
              sx={{
                minHeight: "150px",
                mr: { xs: 2, sm: 0 },
                width: { xs: "auto", sm: "150px" },
                mt: { xs: 2, sm: 0 },
                cursor: "pointer",
              }}
              onClick={() => navigateTo("history")}
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <MonetizationOnIcon
                  color="primary"
                  sx={{ mx: "auto" }}
                  fontSize="large"
                />
                <Typography
                  variant="body1"
                  color="primary"
                  sx={{ mt: 2, fontWeight: "bold" }}
                >
                  Payment
                </Typography>
                <Typography
                  variant="body1"
                  color="primary"
                  sx={{ fontWeight: "bold" }}
                >
                  History
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            item
            xs={6}
            sm={3}
            sx={{
              display: { sm: "flex" },
              flexDirection: { sm: "column" },
              alignItems: { sm: "center" },
            }}
          >
            <Card
              elevation={4}
              sx={{
                minHeight: "150px",
                ml: { xs: 2, sm: 0 },
                width: { xs: "auto", sm: "150px" },
                mt: { xs: 2, sm: 0 },
                cursor: "pointer",
              }}
              onClick={() => navigateTo("autoPay")}
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <AccessAlarmsIcon
                  color="primary"
                  sx={{ mx: "auto" }}
                  fontSize="large"
                />
                <Typography
                  variant="body1"
                  color="primary"
                  sx={{ mt: 2, fontWeight: "bold" }}
                >
                  Auto
                </Typography>
                <Typography
                  variant="body1"
                  color="primary"
                  sx={{ fontWeight: "bold" }}
                >
                  Payments
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 4 }}>
          <Grid
            item
            xs={6}
            sm={3}
            sx={{
              display: { sm: "flex" },
              flexDirection: { sm: "column" },
              alignItems: { sm: "center" },
            }}
          >
            <Card
              elevation={4}
              sx={{
                minHeight: "150px",
                ml: { xs: 0, sm: 0 },
                width: { xs: "150px", sm: "150px" },
                mt: { xs: 0, sm: 0 },
                cursor: "pointer",
              }}
              onClick={() => navigateTo("profile")}
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <AccountCircleIcon
                  color="primary"
                  sx={{ mx: "auto" }}
                  fontSize="large"
                />
                <Typography
                  variant="body1"
                  color="primary"
                  sx={{ mt: 2, fontWeight: "bold" }}
                >
                  Account
                </Typography>
                <Typography
                  variant="body1"
                  color="primary"
                  sx={{ fontWeight: "bold" }}
                >
                  Profile
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default PortalNavigation;

import React, { useContext, Fragment } from "react";
import AuthContext from "../context/auth/authContext";
import Portal from "./userPortal/Portal";
import AdminPortal from "./adminPortal/AdminPortal";
import SessionTimeout from "../components/utils/SessionTimeout";

const PortalCrossroad = () => {
  const { user } = useContext(AuthContext);
  if (user !== null) {
    if (user.role === "customer") {
      return (
        <Fragment>
          <Portal />
          <SessionTimeout />
        </Fragment>
      );
    }
    if (user.role === "admin") {
      return (
        <Fragment>
          <AdminPortal />
          <SessionTimeout />
        </Fragment>
      );
    }
  } else {
    return null;
  }
};
export default PortalCrossroad;

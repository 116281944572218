import React, { useEffect, useState, useContext } from "react";
import authContext from "../../../context/auth/authContext";
import alertContext from "../../../context/alert/alertContext";
import NewAlert from "../../../components/alert/NewAlert";
import axios from "axios";
import {
  Card,
  CardContent,
  Container,
  Paper,
  Switch,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
  FormGroup,
  FormControlLabel,
  Typography,
  Button,
  Grid,
} from "@mui/material";
import CreditCardIcon from "@mui/icons-material/CreditCard";

const AutoPay = ({ setPortalPage }) => {
  const { user, editUser } = useContext(authContext);
  const { setAlert } = useContext(alertContext);
  const [paymentMethods, setPaymentMethods] = useState(null);
  const [autoPayDetails, setAutoPayDetails] = useState({
    autoPay: false,
    autoPayPaymentProfileId: "",
    autoPayCardType: "",
  });

  const handleEnableSwitch = () => {
    setAutoPayDetails({ ...autoPayDetails, autoPay: !autoPayDetails.autoPay });
  };

  const [errors, setErrors] = useState({
    autoPayPaymentProfileId: false,
  });

  const helperText = {
    autoPayPaymentProfileId: "Must Select A Payment Method",
  };

  const handleChange = (e) => {
    setAutoPayDetails({
      ...autoPayDetails,
      [e.target.name]: e.target.value,
    });
  };

  const fetchPaymentMethods = async (id) => {
    const res = await axios.get(`/api/paymentProfile/${id}`);
    setPaymentMethods(res.data.paymentProfiles);
  };

  useEffect(() => {
    if (user !== null) {
      fetchPaymentMethods(user.authProfileId);
      setAutoPayDetails({
        autoPay: user.autoPay === "Yes" ? true : false,
        autoPayPaymentProfileId: user.autoPayPaymentProfileId,
        autoPayCardType: user.autoPayCardType,
      });
    }

    //eslint-disable-next-line
  }, [user]);

  const lastFour = (string) => {
    const newString = string.substr(string.length - 4);

    return newString;
  };

  const validation = () => {
    var validated = { ...errors };

    if (autoPayDetails.autoPayPaymentProfileId === "") {
      validated.autoPayPaymentProfileId = true;
    } else {
      validated.autoPayPaymentProfileId = false;
    }

    setErrors(validated);
    return Object.values(validated).every((x) => x === false);
  };

  const lookupCardType = (id) => {
    const type = paymentMethods.filter(
      (method) => method.customerPaymentProfileId === id
    );
    if (type.length > 0) {
      return type[0].payment.creditCard.cardType;
    } else {
      return "";
    }
  };

  const updateAutoPay = async () => {
    const autopayData = {
      abtAccountNumber: user.abtAccountNumber,
      firstName: user.firstName,
      lastName: user.lastName,
      company: user.company,
      email: user.email,
    };
    if (autoPayDetails.autoPay === true) {
      if (validation()) {
        const updates = {
          autoPay: autoPayDetails.autoPay === true ? "Yes" : "No",
          autoPayPaymentProfileId: autoPayDetails.autoPayPaymentProfileId,
          autoPayCardType: lookupCardType(
            autoPayDetails.autoPayPaymentProfileId
          ),
        };
        editUser(user._id, updates);
        setAlert(true, "Auto Pay Settings Updated", "success");
        await axios.post("/api/payment/autopay/enable", autopayData);
      } else {
        setAlert(true, "Select a Payment Method", "error");
      }
    } else if (autoPayDetails.autoPay === false) {
      const updates = {
        autoPay: autoPayDetails.autoPay === true ? "Yes" : "No",
        autoPayPaymentProfileId: "",
        autoPayCardType: "",
      };
      editUser(user._id, updates);
      await axios.post("/api/payment/autopay/disable", autopayData);
      setAlert(true, "Auto Pay Settings Updated", "success");
    }
  };

  const resetSettings = () => {
    setAutoPayDetails({
      autoPay: user.autoPay === "Yes" ? true : false,
      autoPayPaymentProfileId: user.autoPayPaymentProfileId,
    });
  };
  return (
    <Container>
      <Paper elvation={6} sx={{ p: { xs: 1, sm: 4 }, mt: 3 }}>
        <Grid container>
          <Grid item xs={1}>
            <Button
              variant="contained"
              size="small"
              color="warning"
              onClick={() => setPortalPage("nav")}
            >
              Return
            </Button>
          </Grid>
          <Grid item xs={10}>
            <Typography variant="h5" sx={{ textAlign: "center" }}>
              Manage AutoPay
            </Typography>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
        <Card elevation={4} sx={{ p: { xs: 0, sm: 1 }, mt: 3 }}>
          <CardContent>
            <Grid container>
              <Grid item xs={12}>
                <FormGroup sx={{ float: "right" }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={autoPayDetails.autoPay}
                        onChange={handleEnableSwitch}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label={!autoPayDetails.autoPay ? "Disabled" : "Enabled"}
                  />
                </FormGroup>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  color="primary"
                  sx={{ px: 4, fontSize: { xs: ".8rem", sm: "1.5rem" } }}
                >
                  By enabling this setting you are authorizing AB&T Telecom to
                  charge the below payment method for AB&T Telecom invoices.
                </Typography>
              </Grid>
            </Grid>

            {paymentMethods !== null &&
            paymentMethods !== undefined &&
            paymentMethods.length > 0 ? (
              <Grid container>
                <Grid item xs={12} sx={{ pl: 3 }}>
                  <CreditCardIcon
                    color="primary"
                    fontSize="large"
                    sx={{ mt: 3, display: { xs: "none", sm: "inline" } }}
                  />
                  <FormControl
                    variant="outlined"
                    sx={{ mt: 2, width: "90%", ml: 1 }}
                    error={errors.autoPayPaymentProfileId}
                  >
                    <InputLabel id="method">AutoPay Payment Method</InputLabel>
                    <Select
                      labelId="method"
                      id="autoPayPaymentProfileId"
                      name="autoPayPaymentProfileId"
                      value={autoPayDetails.autoPayPaymentProfileId}
                      onChange={handleChange}
                      label="method"
                    >
                      {paymentMethods !== null && paymentMethods !== undefined
                        ? paymentMethods.map((method) => (
                            <MenuItem
                              key={method.customerPaymentProfileId}
                              value={method.customerPaymentProfileId}
                            >
                              {method.payment.creditCard.cardType} (
                              {lastFour(method.payment.creditCard.cardNumber)})
                            </MenuItem>
                          ))
                        : null}
                    </Select>
                    {errors.autoPayPaymentProfileId && (
                      <FormHelperText id="autoPayPaymentProfileId">
                        {helperText.autoPayPaymentProfileId}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            ) : (
              <Grid container sx={{ my: 3 }}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    color="success"
                    onClick={() => setPortalPage("methods")}
                  >
                    Create Payment Method
                  </Button>
                </Grid>
              </Grid>
            )}
            <Grid container sx={{ mt: 2 }}>
              <Grid
                item
                xs={12}
                sx={{ display: "flex", flexDirection: "row-reverse" }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={updateAutoPay}
                  sx={{ ml: 2 }}
                >
                  UPDATE
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  onClick={resetSettings}
                >
                  RESET
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Paper>
      <NewAlert />
    </Container>
  );
};

export default AutoPay;

import React, { useEffect, useState, useContext } from "react";
import authContext from "../../../context/auth/authContext";
import NewAlert from "../../../components/alert/NewAlert";
import AlertContext from "../../../context/alert/alertContext";
import axios from "axios";
import {
  Card,
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Grid,
} from "@mui/material";

const UserProfile = ({ setPortalPage }) => {
  const { user, loadUser } = useContext(authContext);
  const { setAlert } = useContext(AlertContext);
  const [notEditable, setNotEditable] = useState(true);
  const [userProfile, setUserProfile] = useState(null);

  useEffect(() => {
    setUserProfile(user);
  }, [user]);

  const errors = () => {
    console.log("errors");
  };

  const formEntry = (e) => {
    setUserProfile({ ...userProfile, [e.target.id]: e.target.value });
  };

  const helperText = () => {
    console.log("helperText");
  };

  const editMode = () => {
    setNotEditable(false);
  };

  const cancelEdit = () => {
    setUserProfile(user);
    setNotEditable(true);
  };

  const saveEdits = async () => {
    try {
      await axios
        .put(`/api/auth/${userProfile._id}`, userProfile)
        .then((response) => {
          if (response.status === 200) {
            loadUser();
            setNotEditable(true);
            setAlert(true, "Profile Updated", "success");
          }
        })
        .catch((error) => {
          console.log(error);
          setAlert(true, "Update Error", "error");
        });
    } catch (error) {
      console.log(error);
      setAlert(true, "Update Error", "error");
    }
  };

  const resetPassword = () => {
    axios.post("/api/auth/resetpassword", {
      email: userProfile.email,
    });
    setAlert(true, "Reset Email has been sent", "success");
  };

  return (
    <Container>
      <Paper elevation={6} sx={{ p: { xs: 2, sm: 4 }, mt: 3 }}>
        <Grid container>
          <Grid item xs={12} sm={1}>
            {notEditable ? (
              <Button
                variant="contained"
                size="small"
                color="warning"
                onClick={() => setPortalPage("nav")}
              >
                Return
              </Button>
            ) : (
              <Button
                variant="contained"
                size="small"
                color="error"
                onClick={cancelEdit}
              >
                Cancel
              </Button>
            )}
          </Grid>
          <Grid item xs={12} sm={10}>
            <Typography variant="h5" sx={{ textAlign: "center" }}>
              User Account Profile
            </Typography>
          </Grid>
          <Grid item xs={12} sm={1}>
            {notEditable ? (
              <Button
                variant="contained"
                size="small"
                sx={{ float: { xs: "left", sm: "right" } }}
                onClick={editMode}
              >
                EDIT
              </Button>
            ) : (
              <Button
                variant="contained"
                size="small"
                sx={{ float: { xs: "left", sm: "right" } }}
                onClick={saveEdits}
                color="success"
              >
                SAVE
              </Button>
            )}
          </Grid>
        </Grid>
        {userProfile !== null && (
          <>
            <Card sx={{ mt: 2, p: 1 }} elevation={2}>
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="standard"
                    label="AB&T Account #"
                    id="abtAccountNumber"
                    disabled={notEditable}
                    onChange={formEntry}
                    value={userProfile.abtAccountNumber}
                    sx={{ minWidth: "90%" }}
                    error={errors.abtAccountNumber}
                    helperText={
                      errors.abtAccountNumber && helperText.abtAccountNumber
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="standard"
                    label="Account Profile ID"
                    id="abtAccountNumber"
                    disabled
                    onChange={formEntry}
                    value={userProfile.authProfileId}
                    sx={{ minWidth: "90%" }}
                  />
                </Grid>
              </Grid>
            </Card>
            <Grid container sx={{ mt: 3 }}>
              <Grid item xs={12} sm={6}>
                <Card
                  sx={{
                    padding: { xs: "16px", sm: "32px 32px 48px 32px" },
                    maxWidth: { xs: "100%", sm: "90%" },
                  }}
                  elevation={2}
                >
                  <Grid container>
                    <Grid item xs={12}>
                      <TextField
                        variant="standard"
                        label="Company"
                        id="company"
                        disabled={notEditable}
                        onChange={formEntry}
                        value={userProfile.company}
                        sx={{ minWidth: "100%" }}
                        error={errors.company}
                        helperText={errors.company && helperText.company}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant="standard"
                        label="First Name"
                        id="firstName"
                        disabled={notEditable}
                        onChange={formEntry}
                        value={userProfile.firstName}
                        sx={{ minWidth: "48%" }}
                        error={errors.firstName}
                        helperText={errors.firstName && helperText.firstName}
                      />
                      <TextField
                        variant="standard"
                        label="Last Name"
                        id="lastName"
                        disabled={notEditable}
                        onChange={formEntry}
                        value={userProfile.lastName}
                        sx={{ minWidth: "50%", ml: 1 }}
                        error={errors.lastName}
                        helperText={errors.lastName && helperText.lastName}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant="standard"
                        label="Address"
                        id="address"
                        disabled={notEditable}
                        onChange={formEntry}
                        value={userProfile.address}
                        sx={{ minWidth: "100%" }}
                        error={errors.address}
                        helperText={errors.address && helperText.address}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant="standard"
                        label="City"
                        id="city"
                        disabled={notEditable}
                        onChange={formEntry}
                        value={userProfile.city}
                        sx={{ minWidth: "100%" }}
                        error={errors.city}
                        helperText={errors.city && helperText.city}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant="standard"
                        label="State"
                        id="state"
                        disabled={notEditable}
                        onChange={formEntry}
                        value={userProfile.state}
                        sx={{ minWidth: "100%" }}
                        error={errors.state}
                        helperText={errors.state && helperText.state}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant="standard"
                        label="Zip"
                        id="zip"
                        disabled={notEditable}
                        onChange={formEntry}
                        value={userProfile.zip}
                        sx={{ minWidth: "100%" }}
                        error={errors.zip}
                        helperText={errors.zip && helperText.zip}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant="standard"
                        label="Phone"
                        id="phoneNumber"
                        disabled={notEditable}
                        onChange={formEntry}
                        value={userProfile.phoneNumber}
                        sx={{ minWidth: "100%" }}
                        error={errors.phoneNumber}
                        helperText={errors.phone && helperText.phoneNumber}
                      />
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Card
                  sx={{
                    padding: { xs: "16px", sm: "32px 32px 48px 32px" },
                    maxWidth: { xs: "100%", sm: "90%" },
                  }}
                  elevation={2}
                >
                  <Grid container>
                    <Grid item xs={12}>
                      <TextField
                        variant="standard"
                        label="Username (Email Address)"
                        id="company"
                        disabled={notEditable}
                        onChange={formEntry}
                        value={userProfile.email}
                        sx={{ minWidth: "100%" }}
                        error={errors.email}
                        helperText={errors.email && helperText.email}
                      />
                    </Grid>
                  </Grid>
                </Card>
                <Grid item xs={12}>
                  {notEditable && (
                    <Button
                      variant="contained"
                      size="small"
                      sx={{ mt: 2 }}
                      onClick={resetPassword}
                      color="success"
                    >
                      SEND PASSWORD RESET
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Paper>
      <NewAlert />
    </Container>
  );
};

export default UserProfile;

import React, { useState, useContext } from "react";
import {
  Button,
  Grid,
  Typography,
  Container,
  Card,
  CardContent,
  TextField,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Backdrop,
  Link,
} from "@mui/material";
import LocalAtmSharpIcon from "@mui/icons-material/LocalAtmSharp";
import EmailSharpIcon from "@mui/icons-material/EmailSharp";
import axios from "axios";
import alertContext from "../../context/alert/alertContext";
import CircularProgress from "@mui/material/CircularProgress";
import NewAlert from "../../components/alert/NewAlert";

const Home = () => {
  const { setAlert } = useContext(alertContext);
  const [paymentInformation, setPaymentInformation] = useState({
    abtAccountNumber: "",
    invoices: "",
    company: "",
    cardName: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
    cardType: "",
    cardNumber: "",
    expirationDateA: "",
    expirationDateB: "",
    cvv: "",
    email: "",
    amount: "",
  });
  const [errors, setErrors] = useState({
    abtAccountNumber: false,
    invoices: false,
    company: false,
    cardName: false,
    address: false,
    city: false,
    state: false,
    zip: false,
    phone: false,
    cardType: false,
    cardNumber: false,
    expirationDateA: false,
    expirationDateB: false,
    cvv: false,
    email: false,
    amount: false,
  });
  const [cardTypeCheckboxes, setCardTypeCheckboxes] = useState({
    visa: false,
    mc: false,
    amex: false,
    discover: false,
  });
  const [showBackdrop, setShowBackdrop] = useState(false);

  const helperText = {
    abtAccountNumber: "Please enter your account # from your invoice",
    invoices: "Please enter the invoices you would like to pay",
    company: "Please enter the Company Name",
    cardName: "Please enter the name on the credit card",
    address: "Please enter the billing address for the credit card",
    city: "Please enter the city",
    state: "Please enter your state",
    zip: "Please enter your 7 digit zip code",
    phone: "Please enter your phone number",
    cardType: "Please select a card type",
    cardNumber: "Credit Card number is required",
    expirationDateA: "Enter Month (MM)",
    expirationDateB: "Enter Year (YYYY)",
    cvv: "Please enter the security code",
    email: "Please enter your email address",
    amount: "Please enter payment amount",
  };

  const checkBox = (e) => {
    if (e.target.name === "visa") {
      if (cardTypeCheckboxes.visa === false) {
        setCardTypeCheckboxes({
          visa: true,
          mc: false,
          amex: false,
          discover: false,
        });
        setPaymentInformation({ ...paymentInformation, cardType: "visa" });
      } else {
        setCardTypeCheckboxes({
          visa: false,
          mc: false,
          amex: false,
          discover: false,
        });
        setPaymentInformation({ ...paymentInformation, cardType: "" });
      }
    }
    if (e.target.name === "mc") {
      if (cardTypeCheckboxes.mc === false) {
        setCardTypeCheckboxes({
          visa: false,
          mc: true,
          amex: false,
          discover: false,
        });
        setPaymentInformation({ ...paymentInformation, cardType: "mc" });
      } else {
        setCardTypeCheckboxes({
          visa: false,
          mc: false,
          amex: false,
          discover: false,
        });
        setPaymentInformation({ ...paymentInformation, cardType: "" });
      }
    }
    if (e.target.name === "amex") {
      if (cardTypeCheckboxes.amex === false) {
        setCardTypeCheckboxes({
          visa: false,
          mc: false,
          amex: true,
          discover: false,
        });
        setPaymentInformation({ ...paymentInformation, cardType: "amex" });
      } else {
        setCardTypeCheckboxes({
          visa: false,
          mc: false,
          amex: false,
          discover: false,
        });
        setPaymentInformation({ ...paymentInformation, cardType: "" });
      }
    }
    if (e.target.name === "discover") {
      if (cardTypeCheckboxes.discover === false) {
        setCardTypeCheckboxes({
          visa: false,
          mc: false,
          amex: false,
          discover: true,
        });
        setPaymentInformation({ ...paymentInformation, cardType: "discover" });
      } else {
        setCardTypeCheckboxes({
          visa: false,
          mc: false,
          amex: false,
          discover: false,
        });
        setPaymentInformation({ ...paymentInformation, cardType: "" });
      }
    }
  };

  const formEntry = (e) => {
    setPaymentInformation({
      ...paymentInformation,
      [e.target.id]: e.target.value,
    });
  };

  const validation = () => {
    var validated = { ...errors };

    // if (paymentInformation.abtAccountNumber.length === 0) {
    //   validated.abtAccountNumber = true;
    // } else {
    //   validated.abtAccountNumber = false;
    // }
    if (paymentInformation.company === "") {
      validated.company = true;
    } else {
      validated.company = false;
    }
    if (paymentInformation.cardName === "") {
      validated.cardName = true;
    } else {
      validated.cardName = false;
    }
    if (paymentInformation.address === "") {
      validated.address = true;
    } else {
      validated.address = false;
    }
    if (paymentInformation.city === "") {
      validated.city = true;
    } else {
      validated.city = false;
    }
    if (paymentInformation.state === "") {
      validated.state = true;
    } else {
      validated.state = false;
    }
    if (paymentInformation.zip.length !== 5) {
      validated.zip = true;
    } else {
      validated.zip = false;
    }
    if (paymentInformation.phone.length === 0) {
      validated.phone = true;
    } else {
      validated.phone = false;
    }
    if (paymentInformation.cardNumber.length === 0) {
      validated.cardNumber = true;
    } else {
      validated.cardNumber = false;
    }
    if (paymentInformation.expirationDateA.length !== 2) {
      validated.expirationDateA = true;
    } else {
      validated.expirationDateA = false;
    }
    if (paymentInformation.expirationDateB.length !== 4) {
      validated.expirationDateB = true;
    } else {
      validated.expirationDateB = false;
    }
    if (paymentInformation.cvv.length === 0) {
      validated.cvv = true;
    } else {
      validated.cvv = false;
    }
    if (paymentInformation.email.length === 0) {
      validated.email = true;
    } else {
      validated.email = false;
    }
    if (paymentInformation.amount <= 1) {
      validated.amount = true;
    } else {
      validated.amount = false;
    }

    setErrors(validated);
    return Object.values(validated).every((x) => x === false);
  };

  const submitPayment = async () => {
    const results = validation();
    window.scroll(0, 0);
    if (results) {
      setShowBackdrop(true);
      const response = await axios.post(
        "/api/onetimecharge",
        paymentInformation
      );
      if (response.data === "Payment Processed Successfully") {
        resetForm();
        setShowBackdrop(false);
        setAlert(true, "Payment Recieved Successfully", "success");
      } else if (response.data === "Payment Was Declined") {
        setShowBackdrop(false);
        setAlert(true, "Payment was declined", "error");
      } else if (response.data === "There was an Issue") {
        setShowBackdrop(false);
        setAlert(true, "SYSTEM ERROR - Payment NOT processed", "error");
      }
    } else {
      setAlert(true, "Please resolve errors!", "error");
    }
  };

  const resetForm = () => {
    setPaymentInformation({
      abtAccountNumber: "",
      invoices: "",
      company: "",
      cardName: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      phone: "",
      cardType: "",
      cardNumber: "",
      expirationDateA: "",
      expirationDateB: "",
      cvv: "",
      email: "",
      amount: "",
    });

    setErrors({
      abtAccountNumber: false,
      invoices: false,
      company: false,
      cardName: false,
      address: false,
      city: false,
      state: false,
      zip: false,
      phone: false,
      cardType: false,
      cardNumber: false,
      expirationDateA: false,
      expirationDateB: false,
      cvv: false,
      email: false,
      amount: false,
    });
    setCardTypeCheckboxes({
      visa: false,
      mc: false,
      amex: false,
      discover: false,
    });
  };

  return (
    <Container>
      {showBackdrop && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={showBackdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Typography
        variant="h4"
        sx={{
          textAlign: "center",
          mt: { xs: 2, sm: 0 },
          fontSize: { xs: "2rem", sm: "2.125rem" },
        }}
        color="primary"
      >
        Online Payment Portal
      </Typography>
      <Typography
        variant="body1"
        sx={{
          textAlign: "center",
          padding: { xs: "0 20px", sm: "0 20%" },
          fontSize: { xs: ".9rem", sm: "1rem" },
        }}
        color="secondary"
      >
        Use the below form to make a single payment to AB&T Telecom.
      </Typography>
      <span>
        <Typography
          variant="body1"
          color="error"
          sx={{
            textAlign: "center",
            padding: { xs: "0 20px", sm: "0 20%" },
            fontSize: { xs: ".9rem", sm: "1rem" },
            fontWeight: "bold",
          }}
        >
          If you would like to create or manage your payment profile, or sign up
          for autoPay with AB&T Telecom <Link href="/login">click here</Link> or
          login above.
        </Typography>
      </span>
      <Card elevation={4} sx={{ mt: 3, p: { xs: 1, sm: 2 } }}>
        <CardContent>
          <Typography
            variant="h5"
            sx={{
              textAlign: "center",
              fontSize: { xs: "1.35rem", sm: "1.5rem" },
            }}
            color="primary"
          >
            Single Payment Form
          </Typography>
          <Card sx={{ mt: 2, p: 2 }} elevation={2}>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="standard"
                  label="AB&T Account #"
                  id="abtAccountNumber"
                  onChange={formEntry}
                  value={paymentInformation.abtAccountNumber}
                  sx={{ minWidth: "90%" }}
                  error={errors.abtAccountNumber}
                  helperText={
                    errors.abtAccountNumber && helperText.abtAccountNumber
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="standard"
                  label="Invoice #'s"
                  id="invoices"
                  onChange={formEntry}
                  value={paymentInformation.invoices}
                  sx={{ minWidth: "90%" }}
                  error={errors.invoices}
                  helperText={errors.invoices && helperText.invoices}
                />
              </Grid>
            </Grid>
          </Card>
          <Grid container sx={{ mt: 3 }}>
            <Grid item xs={12} sm={6}>
              <Card
                sx={{
                  padding: { xs: "16px", sm: "32px 32px 48px 32px" },
                  maxWidth: { xs: "100%", sm: "90%" },
                }}
                elevation={2}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <TextField
                      variant="standard"
                      label="Company"
                      id="company"
                      onChange={formEntry}
                      value={paymentInformation.company}
                      sx={{ minWidth: "100%" }}
                      error={errors.company}
                      helperText={errors.company && helperText.company}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="standard"
                      label="Name on Card"
                      id="cardName"
                      onChange={formEntry}
                      value={paymentInformation.cardName}
                      sx={{ minWidth: "100%" }}
                      error={errors.cardName}
                      helperText={errors.cardName && helperText.cardName}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="standard"
                      label="Address"
                      id="address"
                      onChange={formEntry}
                      value={paymentInformation.address}
                      sx={{ minWidth: "100%" }}
                      error={errors.address}
                      helperText={errors.address && helperText.address}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="standard"
                      label="City"
                      id="city"
                      onChange={formEntry}
                      value={paymentInformation.city}
                      sx={{ minWidth: "100%" }}
                      error={errors.city}
                      helperText={errors.city && helperText.city}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="standard"
                      label="State"
                      id="state"
                      onChange={formEntry}
                      value={paymentInformation.state}
                      sx={{ minWidth: "100%" }}
                      error={errors.state}
                      helperText={errors.state && helperText.state}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="standard"
                      label="Zip"
                      id="zip"
                      onChange={formEntry}
                      value={paymentInformation.zip}
                      sx={{ minWidth: "100%" }}
                      error={errors.zip}
                      helperText={errors.zip && helperText.zip}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="standard"
                      label="Phone"
                      id="phone"
                      onChange={formEntry}
                      value={paymentInformation.phone}
                      sx={{ minWidth: "100%" }}
                      error={errors.phone}
                      helperText={errors.phone && helperText.phone}
                    />
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Card
                sx={{ p: 3, maxWidth: "100%", mt: { xs: 2, sm: 0 } }}
                elevation={2}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <FormGroup sx={{ flexDirection: "row" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            sx={{ ml: 3 }}
                            id="cardType"
                            name="visa"
                            onChange={checkBox}
                            checked={cardTypeCheckboxes.visa}
                          />
                        }
                        label="VISA"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            sx={{ ml: 3 }}
                            id="cardType"
                            name="mc"
                            onChange={checkBox}
                            checked={cardTypeCheckboxes.mc}
                          />
                        }
                        label="MC"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            sx={{ ml: 3 }}
                            id="cardType"
                            name="amex"
                            onChange={checkBox}
                            checked={cardTypeCheckboxes.amex}
                          />
                        }
                        label="AMEX"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            sx={{ ml: 3 }}
                            id="cardType"
                            name="discover"
                            onChange={checkBox}
                            checked={cardTypeCheckboxes.discover}
                          />
                        }
                        label="DISCOVER"
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="standard"
                      label="Card Number"
                      id="cardNumber"
                      onChange={formEntry}
                      value={paymentInformation.cardNumber}
                      sx={{ minWidth: "100%" }}
                      error={errors.cardNumber}
                      helperText={errors.cardNumber && helperText.cardNumber}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      variant="standard"
                      label="MM"
                      id="expirationDateA"
                      onChange={formEntry}
                      value={paymentInformation.expirationDateA}
                      sx={{ minWidth: "90%" }}
                      error={errors.expirationDateA}
                      helperText={
                        errors.expirationDateA && helperText.expirationDateA
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      variant="standard"
                      label="YYYY"
                      id="expirationDateB"
                      onChange={formEntry}
                      value={paymentInformation.expirationDateB}
                      sx={{ minWidth: "100%" }}
                      error={errors.expirationDateB}
                      helperText={
                        errors.expirationDateB && helperText.expirationDateB
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="standard"
                      label="CVV"
                      id="cvv"
                      onChange={formEntry}
                      value={paymentInformation.cvv}
                      sx={{ minWidth: "100%" }}
                      error={errors.cvv}
                      helperText={errors.cvv && helperText.cvv}
                    />
                  </Grid>
                </Grid>
              </Card>
              <Card
                sx={{
                  padding: { xs: "8px", sm: "16px 32px 35px 32px" },
                  maxWidth: "100%",
                  mt: 2,
                }}
                elevation={2}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <EmailSharpIcon
                      color="primary"
                      fontSize="large"
                      sx={{ mt: 2 }}
                    />
                    <TextField
                      variant="standard"
                      label="Email Reciept To"
                      id="email"
                      onChange={formEntry}
                      value={paymentInformation.email}
                      sx={{ minWidth: { xs: "80%", sm: "90%" }, ml: 1 }}
                      error={errors.email}
                      helperText={errors.email && helperText.email}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <LocalAtmSharpIcon
                      color="primary"
                      fontSize="large"
                      sx={{ mt: 2 }}
                    />
                    <TextField
                      variant="standard"
                      label="Payment Amount"
                      id="amount"
                      onChange={formEntry}
                      value={paymentInformation.amount}
                      sx={{ minWidth: { xs: "80%", sm: "90%" }, ml: 1 }}
                      error={errors.amount}
                      helperText={errors.amount && helperText.amount}
                    />
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>

          <Grid container sx={{ mt: 3 }}>
            <Grid item xs={12}>
              <Button variant="contained" color="error" onClick={resetForm}>
                RESET
              </Button>
              <Button
                variant="contained"
                color="primary"
                sx={{ float: "right" }}
                onClick={submitPayment}
              >
                PAY NOW
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <NewAlert />
    </Container>
  );
};

export default Home;

import React, { useEffect, useState, useContext } from "react";
import authContext from "../../../context/auth/authContext";
import axios from "axios";
import {
  Card,
  Container,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Button,
  Grid,
} from "@mui/material";
import mmddyy from "../../../components/utils/mmddyy";

const PaymentHistory = ({ setPortalPage }) => {
  const { user } = useContext(authContext);
  const [transactions, setTransactions] = useState(null);

  const fetchTransactions = async (id) => {
    const res = await axios.get(`/api/paymentProfile/history/${id}`);
    setTransactions(res.data);
  };

  useEffect(() => {
    if (user !== null) {
      fetchTransactions(user.authProfileId);
    }

    //eslint-disable-next-line
  }, [user]);

  return (
    <Container>
      <Paper elvation={6} sx={{ p: { xs: 2, sm: 4 }, mt: 3 }}>
        <Grid container>
          <Grid item xs={12} sm={1}>
            <Button
              variant="contained"
              size="small"
              color="warning"
              onClick={() => setPortalPage("nav")}
            >
              Return
            </Button>
          </Grid>
          <Grid item xs={12} sm={10}>
            <Typography variant="h5" sx={{ textAlign: "center" }}>
              Transaction History
            </Typography>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
        <Card elvation={4} sx={{ mt: 2 }}>
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: "#2e68b1" }}>
                <TableCell
                  sx={{
                    textAlign: "left",
                    color: "white",
                    display: { xs: "none", sm: "table-cell" },
                  }}
                >
                  Transaction #
                </TableCell>
                <TableCell sx={{ textAlign: "center", color: "white" }}>
                  Trans Date
                </TableCell>
                <TableCell sx={{ textAlign: "center", color: "white" }}>
                  Card Type
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    color: "white",
                    display: { xs: "none", sm: "table-cell" },
                  }}
                >
                  Card Number
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    color: "white",
                  }}
                >
                  Amount
                </TableCell>

                <TableCell
                  sx={{
                    textAlign: "center",
                    color: "white",
                    display: { xs: "none", sm: "table-cell" },
                  }}
                >
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions !== null &&
              transactions !== undefined &&
              transactions.length !== 0
                ? transactions.map((trans) => (
                    <TableRow key={trans.transId} hover>
                      <TableCell
                        sx={{
                          display: { xs: "none", sm: "table-cell" },
                        }}
                      >
                        {trans.transId}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {mmddyy(trans.submitTimeLocal)}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {trans.accountType}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          display: { xs: "none", sm: "table-cell" },
                        }}
                      >
                        {trans.accountNumber}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        $ {trans.settleAmount.toFixed(2)}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          display: { xs: "none", sm: "table-cell" },
                        }}
                      >
                        {trans.transactionStatus}
                      </TableCell>
                    </TableRow>
                  ))
                : null}
            </TableBody>
          </Table>
        </Card>
      </Paper>
    </Container>
  );
};

export default PaymentHistory;

const DownLoadResults = (data) => {
  var csv = [
    [
      "Date",
      "Customer",
      "Invoice_num",
      "Account_num",
      "Amount",
      "AutoPay",
      "CardType",
      "TransId",
    ],
    ...data.map((row) => [
      row.date,
      row.company,
      row.invoice,
      row.abtAccountNumber,
      row.amount,
      row.paymentProfileId,
      row.cardType,
      row.transactionId,
    ]),
  ]
    .map((e) => e.join(","))
    .join("\n");

  return csv;
};

export default DownLoadResults;

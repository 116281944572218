import React, { useState, useEffect } from "react";
import PortalNavigation from "./navigation/PortalNavigation";
import PaymentHistory from "./history/PaymentHistory";
import PaymentMethods from "./methods/PaymentMethods";
import SinglePayments from "./payment/SinglePayment";
import UserProfile from "./profile/UserProfile";
import AutoPay from "./autoPay/AutoPay";
import ReactGA from "react-ga";

const Portal = () => {
  const [portalPage, setPortalPage] = useState("nav");

  useEffect(() => {
    ReactGA.initialize("UA-46646177-7");
    ReactGA.pageview("User" + "-" + portalPage);
    //eslint-disable-next-line
  }, [portalPage]);

  switch (portalPage) {
    default:
    case "nav":
      return <PortalNavigation setPortalPage={setPortalPage} />;
    case "history":
      return <PaymentHistory setPortalPage={setPortalPage} />;
    case "methods":
      return <PaymentMethods setPortalPage={setPortalPage} />;
    case "payment":
      return <SinglePayments setPortalPage={setPortalPage} />;
    case "profile":
      return <UserProfile setPortalPage={setPortalPage} />;
    case "autoPay":
      return <AutoPay setPortalPage={setPortalPage} />;
  }
};

export default Portal;

import React, { useEffect, useState, useContext } from "react";
import AuthContext from "../../context/auth/authContext";
import { Button, Grid } from "@mui/material";
import Logo from "../images/abtLogo.png";
import "../../css/header.css";

const Header = () => {
  const { logoutUser } = useContext(AuthContext);
  const [currentPage, setCurrentPage] = useState("/");
  useEffect(() => {
    setCurrentPage(window.location.pathname);
  }, []);

  const portalLogOut = () => {
    logoutUser();
  };
  return (
    <div>
      <Grid
        container
        sx={{ flexDirection: { xs: "column-reverse", sm: "row" } }}
      >
        <Grid item xs={12} sm={4}>
          <img src={Logo} alt="AB&T Telecom" className="logo" />
        </Grid>
        <Grid item sm={5} sx={{ display: { xs: "none", sm: "block" } }}></Grid>
        <Grid item xs={12} sm={1}>
          {currentPage === "/" && (
            <Button
              variant="contained"
              color="primary"
              size="small"
              sx={{
                mt: { xs: 0, sm: 0.5 },
                mr: { xs: 1, sm: 0 },
                float: { xs: "right", sm: "left" },
              }}
              href="/login"
            >
              LOGIN
            </Button>
          )}
          {currentPage === "/admin/portal" && (
            <Button
              variant="contained"
              color="primary"
              size="small"
              sx={{
                mt: { xs: 0, sm: 0.5 },
                mr: { xs: 1, sm: 0 },
                float: { xs: "right", sm: "left" },
              }}
              onClick={portalLogOut}
              href="/login"
            >
              LOGOUT
            </Button>
          )}
        </Grid>
        <Grid item sm={2} sx={{ display: { xs: "none", sm: "block" } }}></Grid>
      </Grid>
    </div>
  );
};

export default Header;

import React, { useState, useEffect } from "react";
import AdminPortalNavigation from "./navigation/AdminPortalNavigation";
import SinglePayments from "./singlePayments/SinglePayments";
import ProfilePayments from "./profilePayments/ProfilePayments";
import BillpayUsers from "./BillpayUsers/BillpayUsers";
import UserProfile from "../userPortal/profile/UserProfile";
import ManualPayment from "./manualPayment/ManualPayment";
import AutoPay from "./AutoPay/AutoPay";
import ReactGA from "react-ga";

const AdminPortal = () => {
  const [portalPage, setPortalPage] = useState("nav");

  useEffect(() => {
    ReactGA.initialize("UA-46646177-7");
    ReactGA.pageview("Admin" + "-" + portalPage);
    //eslint-disable-next-line
  }, [portalPage]);

  switch (portalPage) {
    default:
    case "nav":
      return <AdminPortalNavigation setPortalPage={setPortalPage} />;
    case "single":
      return <SinglePayments setPortalPage={setPortalPage} />;
    case "profilePayments":
      return <ProfilePayments setPortalPage={setPortalPage} />;
    case "users":
      return <BillpayUsers setPortalPage={setPortalPage} />;
    case "profile":
      return <UserProfile setPortalPage={setPortalPage} />;
    case "ucAutoPay":
      return <AutoPay setPortalPage={setPortalPage} />;
    case "manualPay":
      return <ManualPayment setPortalPage={setPortalPage} />;
  }
};

export default AdminPortal;

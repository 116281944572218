import React, { useEffect, useState, useContext } from "react";
import alertContext from "../../../context/alert/alertContext";
import NewAlert from "../../../components/alert/NewAlert";
import {
  Card,
  Container,
  Paper,
  CardContent,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  TextField,
  Button,
  Grid,
} from "@mui/material";
import LocalAtmSharpIcon from "@mui/icons-material/LocalAtmSharp";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import DescriptionIcon from "@mui/icons-material/Description";
import PersonIcon from "@mui/icons-material/Person";
import axios from "axios";

const ManualPayment = ({ setPortalPage }) => {
  const { setAlert } = useContext(alertContext);
  const [transactionId, setTransactionId] = useState(null);
  const [billPayUsers, setBillPayUsers] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState(null);
  const [paymentDetails, setPaymentDetails] = useState({
    authProfileId: "",
    paymentProfileId: "",
    invoices: "",
    amount: 0.0,
    abtAccountNumber: "",
    firstName: "",
    lastName: "",
    company: "",
  });

  const [errors, setErrors] = useState({
    authProfileId: false,
    paymentProfileId: false,
    amount: false,
  });

  const helperText = {
    authProfileId: "Must Select A Customer",
    amount: "Please enter an amount to pay",
    paymentProfileId: "Please select a payment Method",
  };

  const validation = () => {
    var validated = { ...errors };

    if (paymentDetails.paymentProfileId === "") {
      validated.paymentProfileId = true;
    } else {
      validated.paymentProfileId = false;
    }
    if (paymentDetails.amount === 0) {
      validated.amount = true;
    } else {
      validated.amount = false;
    }
    if (paymentDetails.authProfileId === "") {
      validated.authProfileId = true;
    } else {
      validated.authProfileId = false;
    }

    setErrors(validated);
    return Object.values(validated).every((x) => x === false);
  };

  const handleChange = (e) => {
    setPaymentDetails({ ...paymentDetails, [e.target.name]: e.target.value });
  };

  const lastFour = (string) => {
    const newString = string.substr(string.length - 4);

    return newString;
  };

  const resetForm = () => {
    setPaymentDetails({
      authProfileId: "",
      paymentProfileId: "",
      invoices: "",
      amount: 0.0,
      abtAccountNumber: "",
      firstName: "",
      lastName: "",
      company: "",
    });

    setPaymentMethods(null);
    setTransactionId(null);
    setErrors({ authProfileId: false, paymentProfileId: false, amount: false });
  };

  const getBillPayUsers = async () => {
    const res = await axios.get(`/api/auth/customers`);
    setBillPayUsers(res.data);
  };
  const getPaymentMethods = async (id) => {
    const res = await axios.get(`/api/paymentProfile/${id}`);
    setPaymentMethods(res.data.paymentProfiles);
  };

  useEffect(() => {
    getBillPayUsers();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (paymentDetails.authProfileId !== "") {
      getPaymentMethods(paymentDetails.authProfileId);
      const selectedUser = billPayUsers.filter(
        (user) => user.authProfileId === paymentDetails.authProfileId
      );

      setPaymentDetails({
        ...paymentDetails,
        abtAccountNumber: selectedUser[0].abtAccountNumber,
        firstName: selectedUser[0].firstName,
        lastName: selectedUser[0].lastName,
        company: selectedUser[0].company,
      });
    }
    //eslint-disable-next-line
  }, [paymentDetails.authProfileId]);

  const chargeCard = async () => {
    if (validation()) {
      const paymentInfo = {
        invoices: paymentDetails.invoices,
        amount: paymentDetails.amount,
        customerProfileId: paymentDetails.authProfileId,
        paymentProfileId: paymentDetails.paymentProfileId,
        abtAccountNumber: paymentDetails.abtAccountNumber,
        firstName: paymentDetails.firstName,
        lastName: paymentDetails.lastName,
        company: paymentDetails.company,
      };
      try {
        await axios
          .post("/api/payment/autopay", paymentInfo)
          .then((response) => {
            if (response.data === "Duplicate") {
              setAlert(true, "ERROR: Duplicate Payment received", "error");
            }
            if (response.data === "Declined") {
              setAlert(true, "Payment has been declined", "error");
            } else {
              setTransactionId(response.data);
              setAlert(true, "Payment Successfull", "success");
            }
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      setAlert(true, "Fill In All Details", "error");
    }
  };

  return (
    <Container>
      <Paper
        elevation={6}
        sx={{
          p: 2,
          mt: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Grid container>
          <Grid item xs={1}>
            <Button
              variant="contained"
              size="small"
              color="warning"
              onClick={() => setPortalPage("nav")}
            >
              Return
            </Button>
          </Grid>
          <Grid item xs={10}>
            <Typography variant="h5" sx={{ textAlign: "center" }}>
              Manual Payment
            </Typography>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
        <Card
          sx={{ mt: 2, p: 0, width: { xs: "350px", sm: "500px" } }}
          elevation={2}
        >
          <CardContent>
            {billPayUsers !== null && (
              <Grid container>
                <Grid item xs={12}>
                  <PersonIcon color="primary" fontSize="large" sx={{ mt: 3 }} />
                  <FormControl
                    variant="outlined"
                    sx={{ mt: 2, width: "80%", ml: 1 }}
                    error={errors.authProfileId}
                  >
                    <InputLabel id="authProfileId">Select Customer</InputLabel>
                    <Select
                      labelId="authProfileId"
                      id="authProfileId"
                      name="authProfileId"
                      value={paymentDetails.authProfileId}
                      onChange={handleChange}
                      label="authProfileId"
                    >
                      {billPayUsers !== null && billPayUsers !== undefined
                        ? billPayUsers.map((user) => (
                            <MenuItem
                              key={user.authProfileId}
                              value={user.authProfileId}
                            >
                              {user.company} ({user.abtAccountNumber})
                            </MenuItem>
                          ))
                        : null}
                    </Select>
                    {errors.authProfileId && (
                      <FormHelperText id="authProfileId">
                        {helperText.authProfileId}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            )}
            {paymentMethods !== null &&
            paymentMethods !== undefined &&
            paymentMethods.length > 0 ? (
              <>
                <CreditCardIcon
                  color="primary"
                  fontSize="large"
                  sx={{ mt: 3 }}
                />
                <FormControl
                  variant="outlined"
                  sx={{ mt: 2, width: "80%", ml: 1 }}
                  error={errors.paymentProfileId}
                >
                  <InputLabel id="method">Select Payment Method</InputLabel>
                  <Select
                    labelId="method"
                    id="paymentProfileId"
                    name="paymentProfileId"
                    value={paymentDetails.paymentProfileId}
                    onChange={handleChange}
                    label="method"
                  >
                    {paymentMethods !== null && paymentMethods !== undefined
                      ? paymentMethods.map((method) => (
                          <MenuItem
                            key={method.customerPaymentProfileId}
                            value={method.customerPaymentProfileId}
                          >
                            {method.payment.creditCard.cardType} (
                            {lastFour(method.payment.creditCard.cardNumber)})
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                  {errors.paymentProfileId && (
                    <FormHelperText id="paymentProfileId">
                      {helperText.paymentProfileId}
                    </FormHelperText>
                  )}
                </FormControl>
              </>
            ) : null}

            <Grid container>
              <Grid item xs={12}>
                <DescriptionIcon
                  color="primary"
                  fontSize="large"
                  sx={{ mt: 3 }}
                />

                <TextField
                  variant="outlined"
                  label="Invoice #'s"
                  name="invoices"
                  onChange={handleChange}
                  value={paymentDetails.invoices}
                  sx={{ width: { xs: "80%" }, mt: 2, ml: 1 }}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <LocalAtmSharpIcon
                  color="primary"
                  fontSize="large"
                  sx={{ mt: 3 }}
                />

                <TextField
                  variant="outlined"
                  label="Payment Amount"
                  name="amount"
                  onChange={handleChange}
                  value={paymentDetails.amount}
                  sx={{ width: { xs: "80%" }, mt: 2, ml: 1 }}
                  error={errors.amount}
                  helperText={errors.amount && helperText.amount}
                />
              </Grid>
            </Grid>
            <Grid container>
              {transactionId !== null && (
                <Grid
                  item
                  sx={{
                    mt: 2,
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Typography variant="h6" color="success">
                    Transaction ID # {transactionId}
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid container>
              <Grid
                item
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  sx={{ mt: 3 }}
                  color="error"
                  onClick={resetForm}
                >
                  RESET
                </Button>
                <Button
                  variant="contained"
                  sx={{ mt: 3, ml: { xs: 4, sm: 3 } }}
                  onClick={chargeCard}
                >
                  SUBMIT
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Paper>
      <NewAlert />
    </Container>
  );
};

export default ManualPayment;

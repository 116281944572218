import React, { useState } from "react";

import SinglePaymentDetail from "./SinglePaymentDetail";
import SinglePaymentList from "./SinglePaymentList";

const SinglePayments = ({ setPortalPage }) => {
  const [focusedTransaction, setFocusedTransaction] = useState(null);
  const [pageView, setPageView] = useState("list");

  switch (pageView) {
    default:
    case "list":
      return (
        <SinglePaymentList
          setPortalPage={setPortalPage}
          setPageView={setPageView}
          setFocusedTransaction={setFocusedTransaction}
        />
      );
    case "detail":
      return (
        <SinglePaymentDetail
          focusedTransaction={focusedTransaction}
          setPageView={setPageView}
        />
      );
  }
};

export default SinglePayments;

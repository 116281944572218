import {
  Button,
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import mmddyy from "../../../components/utils/mmddyy";

const ProfilePaymentDetail = ({ focusedTransaction, setPageView }) => {
  return (
    <Container>
      <Paper
        elevation={6}
        sx={{
          p: { xs: 1, sm: 4 },
          mt: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Grid container>
          <Grid item xs={12} sm={4}>
            <Button
              variant="contained"
              color="warning"
              size="small"
              onClick={() => setPageView("list")}
            >
              Return
            </Button>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography
              variant="h5"
              color="primary"
              sx={{ textAlign: "center" }}
            >
              {" "}
              Transaction Detail{" "}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}></Grid>
        </Grid>
        <Table sx={{ width: { xs: "100%", sm: "60%" }, mt: 2 }}>
          <TableBody>
            <TableRow>
              <TableCell
                sx={{ border: "1px solid #2e68b1", fontWeight: "bold" }}
              >
                Date
              </TableCell>
              <TableCell sx={{ border: "1px solid #2e68b1" }}>
                {mmddyy(focusedTransaction.createdAt)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{ border: "1px solid #2e68b1", fontWeight: "bold" }}
              >
                AB&T Account #
              </TableCell>
              <TableCell sx={{ border: "1px solid #2e68b1" }}>
                {focusedTransaction.abtAccountNumber}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{ border: "1px solid #2e68b1", fontWeight: "bold" }}
              >
                Company
              </TableCell>
              <TableCell sx={{ border: "1px solid #2e68b1" }}>
                {focusedTransaction.company}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{ border: "1px solid #2e68b1", fontWeight: "bold" }}
              >
                Name
              </TableCell>
              <TableCell sx={{ border: "1px solid #2e68b1" }}>
                {focusedTransaction.firstName} {focusedTransaction.lastName}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{ border: "1px solid #2e68b1", fontWeight: "bold" }}
              >
                Auth Profile ID
              </TableCell>
              <TableCell sx={{ border: "1px solid #2e68b1" }}>
                {focusedTransaction.customerProfileId}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{ border: "1px solid #2e68b1", fontWeight: "bold" }}
              >
                Auth Payment ID
              </TableCell>
              <TableCell sx={{ border: "1px solid #2e68b1" }}>
                {focusedTransaction.paymentProfileId}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{ border: "1px solid #2e68b1", fontWeight: "bold" }}
              >
                Amount
              </TableCell>
              <TableCell sx={{ border: "1px solid #2e68b1" }}>
                $ {focusedTransaction.amount}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{ border: "1px solid #2e68b1", fontWeight: "bold" }}
              >
                Invoices
              </TableCell>
              <TableCell sx={{ border: "1px solid #2e68b1" }}>
                {focusedTransaction.invoices}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{ border: "1px solid #2e68b1", fontWeight: "bold" }}
              >
                Error
              </TableCell>
              <TableCell sx={{ border: "1px solid #2e68b1" }}>
                {focusedTransaction.error}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{ border: "1px solid #2e68b1", fontWeight: "bold" }}
              >
                Message
              </TableCell>
              <TableCell sx={{ border: "1px solid #2e68b1" }}>
                {focusedTransaction.message}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    </Container>
  );
};

export default ProfilePaymentDetail;

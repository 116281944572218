import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Card,
  Container,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Button,
  Grid,
} from "@mui/material";
import mmddyy from "../../../components/utils/mmddyy";

const SinglePaymentList = ({
  setPortalPage,
  setPageView,
  setFocusedTransaction,
}) => {
  const [transactions, setTransactions] = useState(null);

  const fetchTransactions = async (id) => {
    const res = await axios.get(`/api/onetimecharge`);
    setTransactions(res.data);
  };

  useEffect(() => {
    fetchTransactions();

    //eslint-disable-next-line
  }, []);

  const showDetail = (transaction) => {
    setFocusedTransaction(transaction);
    setPageView("detail");
  };

  return (
    <Container>
      <Paper elvation={6} sx={{ p: { xs: 1, sm: 4 }, mt: 3 }}>
        <Grid container>
          <Grid item xs={12} sm={1}>
            <Button
              variant="contained"
              size="small"
              color="warning"
              onClick={() => setPortalPage("nav")}
            >
              Return
            </Button>
          </Grid>
          <Grid item xs={12} sm={10}>
            <Typography
              variant="h5"
              sx={{ textAlign: "center", mt: { xs: 2, sm: 0 } }}
            >
              Single Payment Transaction History
            </Typography>
          </Grid>
          <Grid item sm={1}></Grid>
        </Grid>
        <Card elvation={4} sx={{ mt: 2 }}>
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: "#2e68b1" }}>
                <TableCell
                  sx={{
                    textAlign: "left",
                    color: "white",
                  }}
                >
                  Date
                </TableCell>
                <TableCell sx={{ textAlign: "center", color: "white" }}>
                  Customer
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    color: "white",
                    display: { xs: "none", sm: "table-cell" },
                  }}
                >
                  Card Type
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    color: "white",
                    display: { xs: "none", sm: "table-cell" },
                  }}
                >
                  AB&T Acct #
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    color: "white",
                  }}
                >
                  Amount
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    color: "white",
                    display: { xs: "none", sm: "table-cell" },
                  }}
                >
                  Transaction #
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    color: "white",
                    display: { xs: "none", sm: "table-cell" },
                  }}
                >
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions !== null && transactions !== undefined
                ? transactions.map((trans) => (
                    <TableRow
                      key={trans.transId}
                      hover
                      onClick={() => showDetail(trans)}
                    >
                      <TableCell
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        {mmddyy(trans.createdAt)}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        {trans.company}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          display: { xs: "none", sm: "table-cell" },
                        }}
                      >
                        {trans.cardType}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          display: { xs: "none", sm: "table-cell" },
                        }}
                      >
                        {trans.abtAccountNumber}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        $ {trans.amount}
                      </TableCell>

                      <TableCell
                        sx={{
                          display: { xs: "none", sm: "table-cell" },
                        }}
                      >
                        {trans.transId}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          display: { xs: "none", sm: "table-cell" },
                        }}
                      >
                        {trans.chargeMessages}
                      </TableCell>
                    </TableRow>
                  ))
                : null}
            </TableBody>
          </Table>
        </Card>
      </Paper>
    </Container>
  );
};

export default SinglePaymentList;

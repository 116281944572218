import React, { useContext, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import AuthContext from "../context/auth/authContext";
import Header from "../components/header/Header";
import PortalCrossroad from "../pages/PortalCrossroad";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated, loadUser, loading } = useContext(AuthContext);

  useEffect(() => {
    if (localStorage.auth_token) {
      loadUser(localStorage.auth_token);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Route
        {...rest}
        render={(props) =>
          !isAuthenticated && loading ? (
            <Redirect to="/login" />
          ) : (
            <>
              <Header />
              <PortalCrossroad />
            </>
          )
        }
      />
    </div>
  );
};

export default PrivateRoute;

import React, { useEffect, useState, useContext } from "react";
import {
  Container,
  Grid,
  Button,
  TextField,
  Card,
  CardContent,
  InputLabel,
  MenuItem,
  FormControl,
  FormHelperText,
  Select,
  Typography,
} from "@mui/material";
import NewAlert from "../../../components/alert/NewAlert";
import axios from "axios";
import alertContext from "../../../context/alert/alertContext";

const BillPayUserDetail = ({ focusedUser, setPageView }) => {
  const { setAlert } = useContext(alertContext);
  const [paymentMethods, setPaymentMethods] = useState(null);
  const [notEditable, setNotEditable] = useState(true);
  const [editedUser, setEditedUser] = useState({
    _id: "",
    abtAccountNumber: "",
    company: "",
    firstName: "",
    lastName: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    phoneNumber: "",
    email: "",
    password: "",
    passwordB: "",
    role: "customer",
    authProfileId: "",
    createdAt: "",
    updatedAt: "",
    active: "",
    autoPay: "",
    autoPayPaymentProfileId: "",
    autoPayCardType: "",
  });

  const fetchPaymentMethods = async (id) => {
    const res = await axios.get(`/api/paymentProfile/${id}`);
    setPaymentMethods(res.data.paymentProfiles);
  };

  useEffect(() => {
    setEditedUser(focusedUser);
    fetchPaymentMethods(focusedUser.authProfileId);
  }, [focusedUser]);

  const [errors, setErrors] = useState({
    company: false,
    firstName: false,
    lastName: false,
    address: false,
    city: false,
    state: false,
    zip: false,
    phoneNumber: false,
    email: false,
    abtAccountNumber: false,
    password: false,
    autoPayProfileId: false,
  });

  const helperText = {
    abtAccountNumber: "Please enter your account # from your invoice",
    company: "Please enter the Company Name",
    firstName: "First Name is Manditory",
    lastName: "Last Name is Manditory",
    address: "Please enter the billing address for the credit card",
    city: "Please enter the city",
    state: "Please enter your state",
    zip: "Please enter your 7 digit zip code",
    phoneNumber: "Please enter your phone number",
    email: "Please enter your email address",
    password:
      "Password must be 8 characters and include a Captial, Lower case, number and symbol",
    passwordB: "Passwords must match",
    autoPayProfileId: "Must Select Payment Method",
  };

  const resetForm = () => {
    setEditedUser(focusedUser);
    setErrors({
      company: false,
      firstName: false,
      lastName: false,
      address: false,
      city: false,
      state: false,
      zip: false,
      phoneNumber: false,
      email: false,
      abtAccountNumber: false,
      autoPayProfileId: false,
    });
  };

  const validation = () => {
    var validated = { ...errors };

    if (editedUser.abtAccountNumber.length === 0) {
      validated.abtAccountNumber = true;
    } else {
      validated.abtAccountNumber = false;
    }
    if (editedUser.company === "") {
      validated.company = true;
    } else {
      validated.company = false;
    }
    if (editedUser.firstName === "") {
      validated.firstName = true;
    } else {
      validated.firstName = false;
    }
    if (editedUser.lastName === "") {
      validated.lastName = true;
    } else {
      validated.lastName = false;
    }
    if (editedUser.address === "") {
      validated.address = true;
    } else {
      validated.address = false;
    }
    if (editedUser.city === "") {
      validated.city = true;
    } else {
      validated.city = false;
    }
    if (editedUser.state === "") {
      validated.state = true;
    } else {
      validated.state = false;
    }
    if (editedUser.zip.length !== 5) {
      validated.zip = true;
    } else {
      validated.zip = false;
    }
    if (editedUser.phoneNumber.length === 0) {
      validated.phoneNumber = true;
    } else {
      validated.phoneNumber = false;
    }
    if (editedUser.email.length === 0) {
      validated.email = true;
    } else {
      validated.email = false;
    }
    if (
      editedUser.autoPay === "Yes" &&
      editedUser.autoPayPaymentProfileId === ""
    ) {
      validated.autoPayProfileId = true;
    } else {
      validated.autoPayProfileId = false;
    }

    setErrors(validated);
    return Object.values(validated).every((x) => x === false);
  };

  const formEntry = (e) => {
    setEditedUser({
      ...editedUser,
      [e.target.id]: e.target.value,
    });
  };

  const selectChange = (e) => {
    setEditedUser({
      ...editedUser,
      [e.target.name]: e.target.value,
    });
  };

  const lookupCardType = (id) => {
    const type = paymentMethods.filter(
      (method) => method.customerPaymentProfileId === id
    );
    if (type.length > 0) {
      return type[0].payment.creditCard.cardType;
    } else {
      return "";
    }
  };

  const updateUser = async () => {
    var updatedCardType = "";
    var updatedCardProfileId = "";
    if (editedUser.autoPay === "Yes") {
      updatedCardType = lookupCardType(editedUser.autoPayPaymentProfileId);
      updatedCardProfileId = editedUser.autoPayPaymentProfileId;
    } else {
      updatedCardType = "";
      updatedCardProfileId = "";
    }
    if (validation()) {
      const updatedUser = {
        _id: editedUser._id,
        abtAccountNumber: editedUser.abtAccountNumber,
        company: editedUser.company,
        firstName: editedUser.firstName,
        lastName: editedUser.lastName,
        address: editedUser.address,
        city: editedUser.city,
        state: editedUser.state,
        zip: editedUser.zip,
        phoneNumber: editedUser.phoneNumber,
        email: editedUser.email,
        role: editedUser.role,
        authProfileId: editedUser.authProfileId,
        updatedAt: Date.now(),
        active: editedUser.active,
        autoPay: editedUser.autoPay,
        autoPayPaymentProfileId: updatedCardProfileId,
        autoPayCardType: updatedCardType,
      };
      await axios
        .put(`/api/auth/${editedUser._id}`, updatedUser)
        .then((response) => {
          if (response.status === 200) {
            setAlert(true, "User is updated", "success");
            setNotEditable(true);
          } else if (response.status === 500) {
            setAlert(true, "Update Error", "error");
          }
        });
    } else {
      setAlert(true, "Fix validation errror(s)", "error");
    }
  };

  const cancelEdit = () => {
    resetForm();
    setNotEditable(true);
  };

  const resetPassword = () => {
    axios.post("/api/auth/resetpassword", {
      email: focusedUser.email,
    });
    setAlert(true, "Reset Email has been sent", "success");
  };

  return (
    <Container>
      <Card elevation={4} sx={{ mt: 3, p: { xs: 1, sm: 2 } }}>
        <CardContent>
          <Grid container>
            <Grid item xs={1}>
              {notEditable && (
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  onClick={() => setPageView("list")}
                >
                  Return
                </Button>
              )}
            </Grid>
            <Grid item xs={10}>
              <Typography
                variant="h5"
                sx={{
                  textAlign: "center",
                  fontSize: { xs: "1.35rem", sm: "1.5rem" },
                }}
                color="primary"
              >
                Bill Pay User Details
              </Typography>
            </Grid>
            <Grid item xs={1}>
              {notEditable && (
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => setNotEditable(false)}
                >
                  Edit
                </Button>
              )}
            </Grid>
          </Grid>
          <Grid container sx={{ mt: 3 }}>
            <Grid item xs={12} sm={6}>
              <Card
                sx={{
                  padding: { xs: "16px", sm: "32px 32px 48px 32px" },
                  maxWidth: { xs: "100%", sm: "90%" },
                }}
                elevation={2}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <TextField
                      variant="standard"
                      label="Company"
                      id="company"
                      onChange={formEntry}
                      value={editedUser.company}
                      sx={{ minWidth: "100%" }}
                      disabled={notEditable}
                      error={errors.company}
                      helperText={errors.company && helperText.company}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="standard"
                      label="First Name"
                      id="firstName"
                      onChange={formEntry}
                      value={editedUser.firstName}
                      sx={{ minWidth: "48%" }}
                      disabled={notEditable}
                      error={errors.firstName}
                      helperText={errors.firstName && helperText.firstName}
                    />
                    <TextField
                      variant="standard"
                      label="Last Name"
                      id="lastName"
                      onChange={formEntry}
                      value={editedUser.lastName}
                      sx={{ minWidth: "50%" }}
                      disabled={notEditable}
                      error={errors.lastName}
                      helperText={errors.lastName && helperText.lastName}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="standard"
                      label="Address"
                      id="address"
                      onChange={formEntry}
                      value={editedUser.address}
                      sx={{ minWidth: "100%" }}
                      disabled={notEditable}
                      error={errors.address}
                      helperText={errors.address && helperText.address}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="standard"
                      label="City"
                      id="city"
                      onChange={formEntry}
                      value={editedUser.city}
                      sx={{ minWidth: "100%" }}
                      disabled={notEditable}
                      error={errors.city}
                      helperText={errors.city && helperText.city}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="standard"
                      label="State"
                      id="state"
                      onChange={formEntry}
                      value={editedUser.state}
                      sx={{ minWidth: "100%" }}
                      disabled={notEditable}
                      error={errors.state}
                      helperText={errors.state && helperText.state}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="standard"
                      label="Zip"
                      id="zip"
                      onChange={formEntry}
                      value={editedUser.zip}
                      sx={{ minWidth: "100%" }}
                      disabled={notEditable}
                      error={errors.zip}
                      helperText={errors.zip && helperText.zip}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="standard"
                      label="Phone"
                      id="phoneNumber"
                      onChange={formEntry}
                      value={editedUser.phoneNumber}
                      sx={{ minWidth: "100%" }}
                      disabled={notEditable}
                      error={errors.phoneNumber}
                      helperText={errors.phone && helperText.phoneNumber}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="standard"
                      label="Email Address (UserName)"
                      id="email"
                      onChange={formEntry}
                      value={editedUser.email}
                      sx={{ minWidth: { xs: "80%", sm: "100%" } }}
                      disabled={notEditable}
                      error={errors.email}
                      helperText={errors.email && helperText.email}
                    />
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Card
                sx={{
                  padding: { xs: "8px", sm: "16px 32px 35px 32px" },
                  maxWidth: "100%",
                  mt: { xs: 1, sm: 0 },
                }}
                elevation={2}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <TextField
                      variant="standard"
                      label="AB&T Account #"
                      id="abtAccountNumber"
                      onChange={formEntry}
                      value={editedUser.abtAccountNumber}
                      sx={{ minWidth: { xs: "80%", sm: "100%" } }}
                      disabled={notEditable}
                      error={errors.abtAccountNumber}
                      helperText={
                        errors.abtAccountNumber && helperText.abtAccountNumber
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="standard"
                      label="Auth Profile ID"
                      id="authProfileId"
                      onChange={formEntry}
                      value={editedUser.authProfileId}
                      sx={{ minWidth: { xs: "80%", sm: "100%" } }}
                      disabled={notEditable}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      sx={{ minWidth: { xs: "80%", sm: "100%" }, mt: 2 }}
                    >
                      <InputLabel id="role">Role</InputLabel>
                      <Select
                        labelId="role"
                        id="role"
                        value={editedUser.role}
                        name="role"
                        label="role"
                        onChange={selectChange}
                        disabled={notEditable}
                      >
                        <MenuItem value="customer">Customer</MenuItem>
                        <MenuItem value="admin">Admin</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      sx={{ minWidth: { xs: "80%", sm: "100%" }, mt: 2 }}
                    >
                      <InputLabel id="active">Active</InputLabel>
                      <Select
                        labelId="active"
                        id="active"
                        name="active"
                        value={editedUser.active}
                        label="active"
                        onChange={selectChange}
                        disabled={notEditable}
                      >
                        <MenuItem value="Yes">Yes</MenuItem>
                        <MenuItem value="No">No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      sx={{ minWidth: { xs: "80%", sm: "100%" }, mt: 2 }}
                    >
                      <InputLabel id="autoPay">AutoPay</InputLabel>
                      <Select
                        labelId="autoPay"
                        id="autoPay"
                        name="autoPay"
                        value={editedUser.autoPay}
                        label="active"
                        onChange={selectChange}
                        disabled={notEditable}
                      >
                        <MenuItem value="Yes">Yes</MenuItem>
                        <MenuItem value="No">No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    {editedUser.autoPay === "Yes" && (
                      <FormControl
                        sx={{ minWidth: { xs: "80%", sm: "100%" }, mt: 2 }}
                        error={errors.autoPayProfileId}
                      >
                        <InputLabel id="autoPay">
                          AutoPay Payment Method
                        </InputLabel>
                        <Select
                          labelId="autoPayPaymentProfileId"
                          id="autoPayPaymentProfileId"
                          name="autoPayPaymentProfileId"
                          value={editedUser.autoPayPaymentProfileId}
                          label="active"
                          onChange={selectChange}
                          disabled={notEditable}
                        >
                          <MenuItem value="">SELECT One</MenuItem>
                          {paymentMethods !== null &&
                          paymentMethods !== undefined
                            ? paymentMethods.map((method) => (
                                <MenuItem
                                  key={method.customerPaymentProfileId}
                                  value={method.customerPaymentProfileId}
                                >
                                  {method.payment.creditCard.cardType} (
                                  {method.payment.creditCard.cardNumber}){" "}
                                </MenuItem>
                              ))
                            : null}
                        </Select>
                        {errors.autoPayProfileId && (
                          <FormHelperText id="autoPayPaymentProfileId">
                            {helperText.autoPayProfileId}
                          </FormHelperText>
                        )}
                      </FormControl>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      size="small"
                      sx={{ mt: 3, float: "right" }}
                      onClick={resetPassword}
                      color="success"
                    >
                      SEND PASSWORD RESET
                    </Button>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
          {!notEditable && (
            <Grid container sx={{ mt: 3 }}>
              <Grid item xs={1}>
                {" "}
              </Grid>
              <Grid item xs={8}></Grid>
              <Grid item xs={3}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  sx={{ float: "right" }}
                  onClick={updateUser}
                >
                  Update
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  size="small"
                  sx={{ float: "right", mr: 1 }}
                  onClick={cancelEdit}
                >
                  CANCEL
                </Button>
              </Grid>
            </Grid>
          )}
        </CardContent>
      </Card>
      <NewAlert />
    </Container>
  );
};

export default BillPayUserDetail;

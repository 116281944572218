import React, { useState, useContext } from "react";
import {
  Container,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Button,
  TextField,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import NewAlert from "../../../components/alert/NewAlert";
import axios from "axios";
import alertContext from "../../../context/alert/alertContext";

const BillPayUserCreate = ({ setPageView }) => {
  const { setAlert } = useContext(alertContext);
  const [newUser, setNewUser] = useState({
    abtAccountNumber: "",
    company: "",
    firstName: "",
    lastName: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    phoneNumber: "",
    email: "",
    password: "",
    passwordB: "",
    role: "customer",
    authProfileId: "",
    createdAt: "",
    updatedAt: "",
    active: "Yes",
    autoPay: "No",
    autoPayPaymentProfileId: "",
  });

  const [errors, setErrors] = useState({
    company: false,
    firstName: false,
    lastName: false,
    address: false,
    city: false,
    state: false,
    zip: false,
    phoneNumber: false,
    email: false,
  });

  const helperText = {
    company: "Please enter the Company Name",
    firstName: "First Name is Manditory",
    lastName: "Last Name is Manditory",
    address: "Please enter the billing address for the credit card",
    city: "Please enter the city",
    state: "Please enter your state",
    zip: "Please enter your 7 digit zip code",
    phoneNumber: "Please enter your phone number",
    email: "Please enter your email address",
    password:
      "Password must be 8 characters and include a Captial, Lower case, number and symbol",
    passwordB: "Passwords must match",
  };

  const resetForm = () => {
    setNewUser({
      abtAccountNumber: "",
      company: "",
      firstName: "",
      lastName: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      phoneNumber: "",
      email: "",
      password: "",
      passwordB: "",
      role: "customer",
      authProfileId: "",
      createdAt: "",
      updatedAt: "",
      active: "Yes",
      autoPay: "No",
      autoPayPaymentProfileId: "",
    });
    setErrors({
      company: false,
      firstName: false,
      lastName: false,
      address: false,
      city: false,
      state: false,
      zip: false,
      phoneNumber: false,
      email: false,
      abtAccountNumber: false,
    });
  };

  const validation = () => {
    var validated = { ...errors };

    if (newUser.company === "") {
      validated.company = true;
    } else {
      validated.company = false;
    }
    if (newUser.firstName === "") {
      validated.firstName = true;
    } else {
      validated.firstName = false;
    }
    if (newUser.lastName === "") {
      validated.lastName = true;
    } else {
      validated.lastName = false;
    }
    if (newUser.address === "") {
      validated.address = true;
    } else {
      validated.address = false;
    }
    if (newUser.city === "") {
      validated.city = true;
    } else {
      validated.city = false;
    }
    if (newUser.state === "") {
      validated.state = true;
    } else {
      validated.state = false;
    }
    if (newUser.zip.length !== 5) {
      validated.zip = true;
    } else {
      validated.zip = false;
    }
    if (newUser.phoneNumber.length === 0) {
      validated.phoneNumber = true;
    } else {
      validated.phoneNumber = false;
    }
    if (newUser.email.length === 0) {
      validated.email = true;
    } else {
      validated.email = false;
    }

    setErrors(validated);
    return Object.values(validated).every((x) => x === false);
  };

  const formEntry = (e) => {
    setNewUser({
      ...newUser,
      [e.target.id]: e.target.value,
    });
  };

  const selectChange = (e) => {
    setNewUser({
      ...newUser,
      [e.target.name]: e.target.value,
    });
  };

  const createUser = async () => {
    if (validation()) {
      await axios.post(`/api/auth/create`, newUser).then((response) => {
        if (response.status === 200) {
          setAlert(true, "User created successfully", "success");
          setPageView("list");
        } else if (response.status === 500) {
          setAlert(true, "Update Error", "error");
        }
      });
    } else {
      setAlert(true, "Fix validation errror(s)", "error");
    }
  };

  const cancelCreate = () => {
    resetForm();
  };

  return (
    <Container>
      <Card elevation={4} sx={{ mt: 3, p: { xs: 1, sm: 2 } }}>
        <CardContent>
          <Grid container>
            <Grid item xs={1}>
              <Button
                variant="contained"
                color="warning"
                size="small"
                onClick={() => setPageView("list")}
              >
                Return
              </Button>
            </Grid>
            <Grid item xs={10}>
              <Typography
                variant="h5"
                sx={{
                  textAlign: "center",
                  fontSize: { xs: "1.35rem", sm: "1.5rem" },
                }}
                color="primary"
              >
                Bill Pay User Details
              </Typography>
            </Grid>
            <Grid item xs={1}></Grid>
          </Grid>
          <Grid container sx={{ mt: 3 }}>
            <Grid item xs={12} sm={6}>
              <Card
                sx={{
                  padding: { xs: "16px", sm: "32px 32px 48px 32px" },
                  maxWidth: { xs: "100%", sm: "90%" },
                }}
                elevation={2}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <TextField
                      variant="standard"
                      label="Company"
                      id="company"
                      onChange={formEntry}
                      value={newUser.company}
                      sx={{ minWidth: "100%" }}
                      error={errors.company}
                      helperText={errors.company && helperText.company}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="standard"
                      label="First Name"
                      id="firstName"
                      onChange={formEntry}
                      value={newUser.firstName}
                      sx={{ minWidth: "48%" }}
                      error={errors.firstName}
                      helperText={errors.firstName && helperText.firstName}
                    />
                    <TextField
                      variant="standard"
                      label="Last Name"
                      id="lastName"
                      onChange={formEntry}
                      value={newUser.lastName}
                      sx={{ minWidth: "50%" }}
                      error={errors.lastName}
                      helperText={errors.lastName && helperText.lastName}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="standard"
                      label="Address"
                      id="address"
                      onChange={formEntry}
                      value={newUser.address}
                      sx={{ minWidth: "100%" }}
                      error={errors.address}
                      helperText={errors.address && helperText.address}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="standard"
                      label="City"
                      id="city"
                      onChange={formEntry}
                      value={newUser.city}
                      sx={{ minWidth: "100%" }}
                      error={errors.city}
                      helperText={errors.city && helperText.city}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="standard"
                      label="State"
                      id="state"
                      onChange={formEntry}
                      value={newUser.state}
                      sx={{ minWidth: "100%" }}
                      error={errors.state}
                      helperText={errors.state && helperText.state}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="standard"
                      label="Zip"
                      id="zip"
                      onChange={formEntry}
                      value={newUser.zip}
                      sx={{ minWidth: "100%" }}
                      error={errors.zip}
                      helperText={errors.zip && helperText.zip}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="standard"
                      label="Phone"
                      id="phoneNumber"
                      onChange={formEntry}
                      value={newUser.phoneNumber}
                      sx={{ minWidth: "100%" }}
                      error={errors.phoneNumber}
                      helperText={errors.phone && helperText.phoneNumber}
                    />
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Card
                sx={{
                  padding: { xs: "8px", sm: "16px 32px 35px 32px" },
                  maxWidth: "100%",
                  mt: 2,
                }}
                elevation={2}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <TextField
                      variant="standard"
                      label="Email Address (UserName)"
                      id="email"
                      onChange={formEntry}
                      value={newUser.email}
                      sx={{ minWidth: { xs: "80%", sm: "100%" } }}
                      error={errors.email}
                      helperText={errors.email && helperText.email}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="standard"
                      label="AB&T Account #"
                      id="abtAccountNumber"
                      onChange={formEntry}
                      value={newUser.abtAccountNumber}
                      sx={{ minWidth: { xs: "80%", sm: "100%" } }}
                      error={errors.abtAccountNumber}
                      helperText={
                        errors.abtAccountNumber && helperText.abtAccountNumber
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="standard"
                      label="Auth Profile ID"
                      id="authProfileId"
                      onChange={formEntry}
                      value={newUser.authProfileId}
                      sx={{ minWidth: { xs: "80%", sm: "100%" } }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      sx={{ minWidth: { xs: "80%", sm: "100%" }, mt: 2 }}
                    >
                      <InputLabel id="role">Role</InputLabel>
                      <Select
                        labelId="role"
                        id="role"
                        value={newUser.role}
                        name="role"
                        label="role"
                        onChange={selectChange}
                      >
                        <MenuItem value="customer">Customer</MenuItem>
                        <MenuItem value="admin">Admin</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
          <Grid container sx={{ mt: 3 }}>
            <Grid item xs={1}>
              {" "}
            </Grid>
            <Grid item xs={8}></Grid>
            <Grid item xs={3}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                sx={{ float: "right" }}
                onClick={createUser}
              >
                Create
              </Button>
              <Button
                variant="contained"
                color="error"
                size="small"
                sx={{ float: "right", mr: 1 }}
                onClick={cancelCreate}
              >
                Reset
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <NewAlert />
    </Container>
  );
};

export default BillPayUserCreate;

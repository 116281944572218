import React, { useState } from "react";
import BillPayUsersList from "./BillPayUsersList";
import BillPayUserDetails from "./BillPayUserDetail";
import BillPayUserCreate from "./BillPayUserCreate";

const BillpayUsers = ({ setPortalPage }) => {
  const [focusedUser, setFocusedUser] = useState(null);
  const [pageView, setPageView] = useState("list");

  switch (pageView) {
    default:
    case "list":
      return (
        <BillPayUsersList
          setPortalPage={setPortalPage}
          setPageView={setPageView}
          setFocusedUser={setFocusedUser}
        />
      );
    case "detail":
      return (
        <BillPayUserDetails
          focusedUser={focusedUser}
          setPageView={setPageView}
        />
      );
    case "create":
      return <BillPayUserCreate setPageView={setPageView} />;
  }
};

export default BillpayUsers;

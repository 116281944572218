import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages/home/Home";
import Header from "./components/header/Header";
import AlertState from "./context/alert/AlertState";
import AuthState from "./context/auth/AuthState";
import Login from "./pages/login/Login";
import Registration from "./pages/registration/Registration";
import RequestPasswordReset from "./pages/passwordReset/RequestPasswordReset";
import ResetPassword from "./pages/passwordReset/ResetPassword";
import PrivateRoute from "./routing/PrivateRoute";

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#2e68b1",
      },
      secondary: {
        main: "#161515",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <AlertState>
          <AuthState>
            <div className="App">
              <Switch>
                <Route exact path="/login">
                  <Header />
                  <Login />
                </Route>
                <Route exact path="/admin/portal">
                  <PrivateRoute />
                </Route>
                <Route exact path="/register">
                  <Header />
                  <Registration />
                </Route>
                <Route exact path="/passwordReset">
                  <Header />
                  <RequestPasswordReset />
                </Route>
                <Route
                  path="/resetPassword/:token"
                  component={ResetPassword}
                ></Route>
                <Route path="/">
                  <Header />
                  <Home />
                </Route>
              </Switch>
            </div>
          </AuthState>
        </AlertState>
      </Router>
    </ThemeProvider>
  );
}

export default App;

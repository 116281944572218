import React, { useEffect, useState, useContext } from "react";
import authContext from "../../../context/auth/authContext";
import alertContext from "../../../context/alert/alertContext";
import NewAlert from "../../../components/alert/NewAlert";
import {
  Card,
  Container,
  Paper,
  CardContent,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  TextField,
  Button,
  Grid,
} from "@mui/material";
import LocalAtmSharpIcon from "@mui/icons-material/LocalAtmSharp";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import DescriptionIcon from "@mui/icons-material/Description";
import axios from "axios";

const SinglePayment = ({ setPortalPage }) => {
  const { user } = useContext(authContext);
  const { setAlert } = useContext(alertContext);
  const [paymentMethods, setPaymentMethods] = useState(null);
  const [paymentDetails, setPaymentDetails] = useState({
    paymentProfileId: "",
    invoices: "",
    amount: 0.0,
    customerProfileId: "",
    firstName: "",
    lastName: "",
    company: "",
  });
  const [errors, setErrors] = useState({
    paymentProfileId: false,
    amount: false,
  });

  const helperText = {
    paymentProfileId: "Must Select A Payment Method",
    amount: "Please enter an amount to pay",
  };

  const validation = () => {
    var validated = { ...errors };

    if (paymentDetails.paymentProfileId === "") {
      validated.paymentProfileId = true;
    } else {
      validated.paymentProfileId = false;
    }
    if (paymentDetails.amount === 0) {
      validated.amount = true;
    } else {
      validated.amount = false;
    }

    setErrors(validated);
    return Object.values(validated).every((x) => x === false);
  };

  const handleChange = (e) => {
    setPaymentDetails({
      ...paymentDetails,
      [e.target.name]: e.target.value,
    });
  };

  const fetchPaymentMethods = async (id) => {
    const res = await axios.get(`/api/paymentProfile/${id}`);
    setPaymentMethods(res.data.paymentProfiles);
  };

  useEffect(() => {
    if (user !== null) {
      fetchPaymentMethods(user.authProfileId);
      setPaymentDetails({
        ...paymentDetails,
        customerProfileId: user.authProfileId,
        firstName: user.firstName,
        lastName: user.lastName,
        company: user.company,
        abtAccountNumber: user.abtAccountNumber,
      });
    }

    //eslint-disable-next-line
  }, [user]);

  const lastFour = (string) => {
    const newString = string.substr(string.length - 4);

    return newString;
  };

  const processPayment = async () => {
    if (validation()) {
      await axios
        .post("/api/payment", paymentDetails)
        .then((response) => {
          if (response.data === "Success") {
            setPaymentDetails({
              paymentProfileId: "",
              invoices: "",
              amount: 0.0,
              customerProfileId: "",
              firstName: "",
              lastName: "",
              company: "",
            });
            setAlert(true, "Payment Received", "success");
          }
          if (response.data === "Duplicate") {
            setAlert(true, "ERROR: Duplicate Payment received", "error");
          }
          if (response.data === "Declined") {
            setAlert(true, "Payment has been declined", "error");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setAlert(true, "Must Select a Payment Method & Amount", "error");
    }
  };

  return (
    <Container>
      <Paper
        elevation={6}
        sx={{
          py: 6,
          mt: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Card sx={{ p: 2, width: { xs: "350px", sm: "500px" } }} elevation={2}>
          <CardContent>
            <Typography
              variant="h5"
              sx={{ textAlign: "center" }}
              color="primary"
            >
              Make Single Payment{" "}
            </Typography>
            {paymentMethods !== null &&
            paymentMethods !== undefined &&
            paymentMethods.length > 0 ? (
              <>
                <CreditCardIcon
                  color="primary"
                  fontSize="large"
                  sx={{ mt: 3 }}
                />
                <FormControl
                  variant="outlined"
                  sx={{ mt: 2, width: "80%", ml: 1 }}
                  error={errors.paymentProfileId}
                >
                  <InputLabel id="method">Select Payment Method</InputLabel>
                  <Select
                    labelId="method"
                    id="paymentProfileId"
                    name="paymentProfileId"
                    value={paymentDetails.paymentProfileId}
                    onChange={handleChange}
                    label="method"
                  >
                    {paymentMethods !== null && paymentMethods !== undefined
                      ? paymentMethods.map((method) => (
                          <MenuItem
                            key={method.customerPaymentProfileId}
                            value={method.customerPaymentProfileId}
                          >
                            {method.payment.creditCard.cardType} (
                            {lastFour(method.payment.creditCard.cardNumber)})
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                  {errors.paymentProfileId && (
                    <FormHelperText id="paymentProfileId">
                      {helperText.paymentProfileId}
                    </FormHelperText>
                  )}
                </FormControl>
              </>
            ) : (
              <Grid container sx={{ my: 2 }}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    color="success"
                    onClick={() => setPortalPage("methods")}
                  >
                    Create Payment Method
                  </Button>
                </Grid>
              </Grid>
            )}

            <Grid container>
              <Grid item xs={12}>
                <DescriptionIcon
                  color="primary"
                  fontSize="large"
                  sx={{ mt: 3 }}
                />

                <TextField
                  variant="outlined"
                  label="Invoice #'s"
                  name="invoices"
                  onChange={handleChange}
                  value={paymentDetails.invoices}
                  sx={{ width: { xs: "80%" }, mt: 2, ml: 1 }}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <LocalAtmSharpIcon
                  color="primary"
                  fontSize="large"
                  sx={{ mt: 3 }}
                />

                <TextField
                  variant="outlined"
                  label="Payment Amount"
                  name="amount"
                  onChange={handleChange}
                  value={paymentDetails.amount}
                  sx={{ width: { xs: "80%" }, mt: 2, ml: 1 }}
                  error={errors.amount}
                  helperText={errors.amount && helperText.amount}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                item
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  sx={{ mt: 3 }}
                  color="error"
                  onClick={() => setPortalPage("nav")}
                >
                  CANCEL
                </Button>
                <Button
                  variant="contained"
                  sx={{ mt: 3, ml: { xs: 4, sm: 3 } }}
                  onClick={processPayment}
                >
                  SUBMIT
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Paper>
      <NewAlert />
    </Container>
  );
};

export default SinglePayment;

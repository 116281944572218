import React, { useState, useEffect, useContext } from "react";
import alertContext from "../../../context/alert/alertContext";
import NewAlert from "../../../components/alert/NewAlert";
import { CSVReader } from "react-papaparse";
import DownloadResults from "./DownLoadResults";
import mmddyy from "../../../components/utils/mmddyy";
import axios from "axios";
import {
  Container,
  Paper,
  Card,
  CardContent,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Button,
  Grid,
  Typography,
} from "@mui/material";

const AutoPay = ({ setPortalPage }) => {
  const { setAlert } = useContext(alertContext);
  const [paymentList, setPaymentList] = useState(null);
  const [autoPayUsers, setAutoPayUsers] = useState(null);
  const buttonRef = React.createRef();

  const getAutoPayUsers = async () => {
    try {
      const res = await axios.get("/api/auth/autopay");

      setAutoPayUsers(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAutoPayUsers();
    //eslint-disable-next-line
  }, []);

  const lookupPaymentProfileId = (accountNum) => {
    const id = autoPayUsers.filter(
      (user) => user.abtAccountNumber === accountNum
    );

    if (id.length > 0) {
      return id[0].autoPayPaymentProfileId;
    } else {
      return null;
    }
  };
  const lookupCustomerProfileId = (accountNum) => {
    const id = autoPayUsers.filter(
      (user) => user.abtAccountNumber === accountNum
    );
    if (id.length > 0) {
      return id[0].authProfileId;
    } else {
      return null;
    }
  };
  const lookupCardType = (accountNum) => {
    const id = autoPayUsers.filter(
      (user) => user.abtAccountNumber === accountNum
    );
    if (id.length > 0) {
      return id[0].autoPayCardType;
    } else {
      return null;
    }
  };
  const lookupCustomer = (accountNum) => {
    const id = autoPayUsers.filter(
      (user) => user.abtAccountNumber === accountNum
    );
    if (id.length > 0) {
      return id[0].company;
    } else {
      return null;
    }
  };

  const handleOpenDialog = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };
  const handleOnFileLoad = (data) => {
    var dataSet = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i].data[0] !== "") {
        const invoice = {
          date: data[i].data[0],
          invoice: data[i].data[1],
          abtAccountNumber: data[i].data[2],
          amount: data[i].data[3],
          company: lookupCustomer(data[i].data[2]),
          customerProfileId: lookupCustomerProfileId(data[i].data[2]),
          paymentProfileId: lookupPaymentProfileId(data[i].data[2]),
          cardType: lookupCardType(data[i].data[2]),
          transactionId: "",
        };

        const checkAutoPay = autoPayUsers.filter(
          (user) => user.abtAccountNumber === invoice.abtAccountNumber
        );

        if (checkAutoPay.length === 1) {
          dataSet.push(invoice);
        }
      }

      if (i === data.length - 1) {
        setPaymentList(dataSet);
      }
    }
  };

  const handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  const handleOnRemoveFile = (data) => {
    setPaymentList(null);
  };

  const handleRemoveFile = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.removeFile(e);
    }
  };

  const chargePayment = async (paymentInfo) => {
    var updatedPaymentList = [];
    var updatedPayment = paymentInfo;

    try {
      await axios.post("/api/payment/autopay", paymentInfo).then((response) => {
        if (response.data === "Duplicate") {
          updatedPayment.transactionId = response.data;
          updatedPaymentList.push(updatedPayment);

          const list = paymentList.filter(
            (pay) => pay.invoice !== paymentInfo.invoice
          );
          list.map((listItem) => updatedPaymentList.push(listItem));

          setPaymentList(updatedPaymentList);
          setAlert(true, "ERROR: Duplicate Payment received", "error");
        }
        if (response.data === "Declined") {
          updatedPayment.transactionId = response.data;
          updatedPaymentList.push(updatedPayment);

          const list = paymentList.filter(
            (pay) => pay.invoice !== paymentInfo.invoice
          );
          list.map((listItem) => updatedPaymentList.push(listItem));

          setPaymentList(updatedPaymentList);
          setAlert(true, "Payment has been declined", "error");
        } else {
          updatedPayment.transactionId = response.data;
          updatedPaymentList.push(updatedPayment);

          const list = paymentList.filter(
            (pay) => pay.invoice !== paymentInfo.invoice
          );
          list.map((listItem) => updatedPaymentList.push(listItem));

          setPaymentList(updatedPaymentList);
          setAlert(true, "Payment Successfull", "success");
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const exportResults = () => {
    const fileData = DownloadResults(paymentList);
    const date = mmddyy(Date.now());

    var hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv," + encodeURI(fileData);
    hiddenElement.target = "_blank";
    hiddenElement.download = `AutoPay Transaction Results - ${date}.csv`;
    hiddenElement.click();
  };

  return (
    <Container>
      <Paper elevation={6} sx={{ mt: 2, p: 2, mb: 3 }}>
        <Card elevation={4}>
          <CardContent>
            <Grid container>
              <Grid item xs={12} sm={1}>
                <Button
                  variant="contained"
                  size="small"
                  color="warning"
                  onClick={() => setPortalPage("nav")}
                >
                  Return
                </Button>
              </Grid>
              <Grid item xs={12} sm={10}>
                <Typography
                  variant="h5"
                  sx={{ textAlign: "center", mt: { xs: 2, sm: 0 } }}
                >
                  Process AutoPay Report
                </Typography>
              </Grid>
              <Grid item xs={12} sm={1}></Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card elevation={4} sx={{ mt: 2 }}>
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CSVReader
              ref={buttonRef}
              onFileLoad={handleOnFileLoad}
              onError={handleOnError}
              noClick
              noDrag
              onRemoveFile={handleOnRemoveFile}
            >
              {({ file }) => (
                <aside
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: 10,
                  }}
                >
                  <Grid container>
                    <Grid item xs={12} sm={3}>
                      <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={handleOpenDialog}
                        sx={{ mr: 2, mt: { xs: 0, sm: 1 }, mb: 1 }}
                      >
                        Browse file
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <div
                        style={{
                          borderWidth: 1,
                          borderStyle: "solid",
                          borderColor: "#ccc",
                          height: 45,
                          lineHeight: 2.5,
                          paddingLeft: 13,
                          paddingTop: 3,
                          minWidth: "300px",
                        }}
                      >
                        {file && file.name}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Button
                        size="small"
                        variant="contained"
                        color="error"
                        sx={{ ml: { xs: 0, sm: 2 }, mt: 1 }}
                        onClick={handleRemoveFile}
                      >
                        Remove
                      </Button>
                      <Button
                        size="small"
                        variant="contained"
                        color="warning"
                        sx={{ ml: { xs: 0, sm: 2 }, mt: 1 }}
                        onClick={exportResults}
                      >
                        Export
                      </Button>
                    </Grid>
                  </Grid>
                </aside>
              )}
            </CSVReader>
          </CardContent>
        </Card>
        <NewAlert />

        <Card elevation={4} sx={{ mt: 2 }}>
          <CardContent>
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: "#2e68b1" }}>
                  <TableCell sx={{ color: "white", textAlign: "center" }}>
                    Date
                  </TableCell>
                  <TableCell sx={{ color: "white", textAlign: "center" }}>
                    Customer
                  </TableCell>

                  <TableCell sx={{ color: "white", textAlign: "center" }}>
                    Invoice #
                  </TableCell>
                  <TableCell sx={{ color: "white", textAlign: "center" }}>
                    Account #
                  </TableCell>
                  <TableCell sx={{ color: "white", textAlign: "center" }}>
                    Amount
                  </TableCell>
                  <TableCell sx={{ color: "white", textAlign: "center" }}>
                    AutoPay
                  </TableCell>
                  <TableCell sx={{ color: "white", textAlign: "center" }}>
                    Card Type
                  </TableCell>
                  <TableCell sx={{ color: "white", textAlign: "center" }}>
                    Trans ID
                  </TableCell>
                  <TableCell sx={{ color: "white" }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paymentList !== null
                  ? paymentList.map((pay) => (
                      <TableRow key={pay.invoice} hover>
                        <TableCell>{pay.date}</TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          {pay.company}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          {pay.invoice}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          {pay.abtAccountNumber}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          ${pay.amount}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          {pay.paymentProfileId}
                        </TableCell>
                        <TableCell>{pay.cardType}</TableCell>
                        <TableCell>{pay.transactionId}</TableCell>
                        <TableCell>
                          {pay.transactionId === "" && (
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              sx={{ float: "right" }}
                              onClick={() => chargePayment(pay)}
                            >
                              CHARGE
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    ))
                  : null}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </Paper>
    </Container>
  );
};

export default AutoPay;

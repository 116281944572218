import React, { useState } from "react";
import ProfilePaymentDetail from "./ProfilePaymentDetail";
import ProfilePaymentList from "./ProfilePaymentList";

const ProfilePayments = ({ setPortalPage }) => {
  const [focusedTransaction, setFocusedTransaction] = useState(null);
  const [pageView, setPageView] = useState("list");

  switch (pageView) {
    default:
    case "list":
      return (
        <ProfilePaymentList
          setPortalPage={setPortalPage}
          setPageView={setPageView}
          setFocusedTransaction={setFocusedTransaction}
        />
      );
    case "detail":
      return (
        <ProfilePaymentDetail
          focusedTransaction={focusedTransaction}
          setPageView={setPageView}
        />
      );
  }
};

export default ProfilePayments;

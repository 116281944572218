import React, { useEffect, useState, useContext } from "react";
import authContext from "../../../context/auth/authContext";
import NewAlert from "../../../components/alert/NewAlert";
import axios from "axios";
import {
  Card,
  Container,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  IconButton,
  Button,
  Grid,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const MethodsList = ({
  setLoadedPage,
  setFocusedMethod,
  focusedMethod,
  setPortalPage,
}) => {
  const { user } = useContext(authContext);
  const [paymentMethods, setPaymentMethods] = useState(null);

  const fetchPaymentMethods = async (id) => {
    const res = await axios.get(`/api/paymentProfile/${id}`);
    setPaymentMethods(res.data.paymentProfiles);
  };

  useEffect(() => {
    if (user !== null) {
      fetchPaymentMethods(user.authProfileId);
    }

    //eslint-disable-next-line
  }, [user]);

  const editMethod = (profile) => {
    setFocusedMethod(profile);
    setLoadedPage("edit");
  };

  const deleteMethod = async (profile) => {
    const profileToDelete = {
      customerProfileId: user.authProfileId,
      customerPaymentProfileId: profile.customerPaymentProfileId,
    };
    await axios.post(`/api/paymentProfile/delete/`, profileToDelete);
    var updatedList = [];
    const extractedList = paymentMethods.filter(
      (method) =>
        method.customerPaymentProfileId !== profile.customerPaymentProfileId
    );

    extractedList.map((method) => updatedList.push(method));
    setPaymentMethods(updatedList);
  };

  return (
    <Container>
      <Paper elvation={6} sx={{ p: 2, mt: 3 }}>
        <Grid container>
          <Grid item xs={12} sm={1}>
            <Button
              variant="contained"
              size="small"
              color="warning"
              onClick={() => setPortalPage("nav")}
            >
              Return
            </Button>
          </Grid>
          <Grid item xs={12} sm={10}>
            <Typography variant="h5" sx={{ textAlign: "center" }}>
              Payment Methods
            </Typography>
          </Grid>
          <Grid item xs={12} sm={1}>
            <Button
              variant="contained"
              size="small"
              sx={{ float: { xs: "left", sm: "right" } }}
              onClick={() => setLoadedPage("create")}
            >
              NEW
            </Button>
          </Grid>
        </Grid>
        <Card elvation={4} sx={{ p: 0, mt: 2 }}>
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: "#2e68b1" }}>
                <TableCell
                  sx={{
                    textAlign: "left",
                    color: "white",
                    display: { xs: "none", sm: "table-cell" },
                  }}
                >
                  Profile Id
                </TableCell>
                <TableCell sx={{ textAlign: "center", color: "white" }}>
                  Card Type
                </TableCell>
                <TableCell sx={{ textAlign: "center", color: "white" }}>
                  Card Number
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    color: "white",
                    display: { xs: "none", sm: "table-cell" },
                  }}
                >
                  Expiration
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    color: "white",
                    display: { xs: "none", sm: "table-cell" },
                  }}
                >
                  Name
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    color: "white",
                  }}
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paymentMethods !== null && paymentMethods !== undefined
                ? paymentMethods.map((profile) => (
                    <TableRow key={profile.customerPaymentProfileId} hover>
                      <TableCell
                        sx={{
                          display: { xs: "none", sm: "table-cell" },
                        }}
                      >
                        {profile.customerPaymentProfileId}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {profile.payment.creditCard.cardType}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {profile.payment.creditCard.cardNumber}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          display: { xs: "none", sm: "table-cell" },
                        }}
                      >
                        {profile.payment.creditCard.expirationDate}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          display: { xs: "none", sm: "table-cell" },
                        }}
                      >
                        {profile.billTo.firstName} {profile.billTo.lastName}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "right",
                        }}
                      >
                        <IconButton
                          aria-label="edit"
                          onClick={() => editMethod(profile)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          aria-label="edit"
                          onClick={() => deleteMethod(profile)}
                        >
                          <DeleteForeverIcon color="error" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                : null}
            </TableBody>
          </Table>
        </Card>
        <NewAlert />
      </Paper>
    </Container>
  );
};

export default MethodsList;

import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Card,
  Container,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Button,
  Grid,
} from "@mui/material";
import NewAlert from "../../../components/alert/NewAlert";

const BillPayUsersList = ({ setFocusedUser, setPortalPage, setPageView }) => {
  const [billPayUsers, setBillPayUsers] = useState(null);

  const fetchUsers = async (id) => {
    const res = await axios.get(`/api/auth/all`);
    setBillPayUsers(res.data);
  };

  useEffect(() => {
    fetchUsers();

    //eslint-disable-next-line
  }, []);

  const viewUserDetails = (user) => {
    setFocusedUser(user);
    setPageView("detail");
  };

  return (
    <Container>
      <Paper elvation={6} sx={{ p: 4, mt: 3 }}>
        <Grid container>
          <Grid item xs={1}>
            <Button
              variant="contained"
              size="small"
              color="warning"
              onClick={() => setPortalPage("nav")}
            >
              Return
            </Button>
          </Grid>
          <Grid item xs={10}>
            <Typography variant="h5" sx={{ textAlign: "center" }}>
              Bill Pay Users
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Button
              variant="contained"
              size="small"
              color="primary"
              sx={{ float: "right" }}
              onClick={() => setPageView("create")}
            >
              Create
            </Button>
          </Grid>
        </Grid>
        <Card elvation={4} sx={{ p: 2, mt: 2 }}>
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: "#2e68b1" }}>
                <TableCell
                  sx={{
                    textAlign: "left",
                    color: "white",
                    display: { xs: "none", sm: "table-cell" },
                  }}
                >
                  First
                </TableCell>
                <TableCell sx={{ textAlign: "center", color: "white" }}>
                  Last
                </TableCell>

                <TableCell sx={{ textAlign: "center", color: "white" }}>
                  Company
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    color: "white",
                    display: { xs: "none", sm: "table-cell" },
                  }}
                >
                  AB&T Acct #
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    color: "white",
                    display: { xs: "none", sm: "table-cell" },
                  }}
                >
                  Role
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    color: "white",
                    display: { xs: "none", sm: "table-cell" },
                  }}
                >
                  Active
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    color: "white",
                    display: { xs: "none", sm: "table-cell" },
                  }}
                >
                  AutoPay
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {billPayUsers !== null && billPayUsers !== undefined
                ? billPayUsers.map((user) => (
                    <TableRow
                      key={user._id}
                      hover
                      onClick={() => viewUserDetails(user)}
                    >
                      <TableCell sx={{ textAlign: "left" }}>
                        {user.firstName}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          display: { xs: "none", sm: "table-cell" },
                        }}
                      >
                        {user.lastName}
                      </TableCell>

                      <TableCell sx={{ textAlign: "center" }}>
                        {user.company}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          display: { xs: "none", sm: "table-cell" },
                        }}
                      >
                        {user.abtAccountNumber}
                      </TableCell>

                      <TableCell
                        sx={{
                          textAlign: "center",
                          display: { xs: "none", sm: "table-cell" },
                        }}
                      >
                        {user.role}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          display: { xs: "none", sm: "table-cell" },
                        }}
                      >
                        {user.active}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          display: { xs: "none", sm: "table-cell" },
                        }}
                      >
                        {user.autoPay}
                      </TableCell>
                    </TableRow>
                  ))
                : null}
            </TableBody>
          </Table>
        </Card>
      </Paper>
      <NewAlert />
    </Container>
  );
};

export default BillPayUsersList;

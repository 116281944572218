import React, { useState, useContext } from "react";
import {
  Container,
  Backdrop,
  CircularProgress,
  Grid,
  Button,
  TextField,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import NewAlert from "../../components/alert/NewAlert";
import axios from "axios";
import alertContext from "../../context/alert/alertContext";
import { Redirect } from "react-router-dom";

const Registration = () => {
  const { setAlert } = useContext(alertContext);
  const [registrationStatus, setRegistrationStatus] = useState("In Progress");
  const [customer, setCustomer] = useState({
    abtAccountNumber: "",
    company: "",
    firstName: "",
    lastName: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    phoneNumber: "",
    email: "",
    password: "",
    passwordB: "",
    role: "customer",
    active: "Yes",
    autoPay: "No",
    autoPayPaymentProfileId: "",
  });
  const [errors, setErrors] = useState({
    company: false,
    firstName: false,
    lastName: false,
    address: false,
    city: false,
    state: false,
    zip: false,
    phoneNumber: false,
    email: false,
    abtAccountNumber: false,
    password: false,
  });

  const [showBackdrop, setShowBackdrop] = useState(false);

  const helperText = {
    abtAccountNumber: "Please enter your account # from your invoice",
    company: "Please enter the Company Name",
    firstName: "First Name is Manditory",
    lastName: "Last Name is Manditory",
    address: "Please enter the billing address for the credit card",
    city: "Please enter the city",
    state: "Please enter your state",
    zip: "Please enter your 7 digit zip code",
    phoneNumber: "Please enter your phone number",
    email: "Please enter your email address",
    password:
      "Password must be 8 characters and include a Captial, Lower case, number and symbol",
    passwordB: "Passwords must match",
  };

  const resetForm = () => {
    setCustomer({
      company: "",
      firstName: "",
      lastName: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      phoneNumber: "",
      cardNumber: "",
      expirationDateA: "",
      expirationDateB: "",
      cardCode: "",
      email: "",
      abtAccountNumber: "",
      cardType: "",
      password: "",
      passwordB: "",
      role: "customer",
      active: "Yes",
      autoPay: "No",
      autoPayPaymentProfileId: "",
    });
    setErrors({
      company: false,
      firstName: false,
      lastName: false,
      address: false,
      city: false,
      state: false,
      zip: false,
      phoneNumber: false,
      email: false,
      abtAccountNumber: false,
    });
  };

  const validation = () => {
    var validated = { ...errors };

    if (customer.abtAccountNumber.length === 0) {
      validated.abtAccountNumber = true;
    } else {
      validated.abtAccountNumber = false;
    }
    if (customer.company === "") {
      validated.company = true;
    } else {
      validated.company = false;
    }
    if (customer.firstName === "") {
      validated.firstName = true;
    } else {
      validated.firstName = false;
    }
    if (customer.lastName === "") {
      validated.lastName = true;
    } else {
      validated.lastName = false;
    }
    if (customer.address === "") {
      validated.address = true;
    } else {
      validated.address = false;
    }
    if (customer.city === "") {
      validated.city = true;
    } else {
      validated.city = false;
    }
    if (customer.state === "") {
      validated.state = true;
    } else {
      validated.state = false;
    }
    if (customer.zip.length !== 5) {
      validated.zip = true;
    } else {
      validated.zip = false;
    }
    if (customer.phoneNumber.length === 0) {
      validated.phoneNumber = true;
    } else {
      validated.phoneNumber = false;
    }
    if (customer.email.length === 0) {
      validated.email = true;
    } else {
      validated.email = false;
    }
    if (customer.password.length < 8) {
      validated.password = true;
    } else {
      validated.password = false;
    }
    if (customer.passwordB !== customer.password) {
      validated.passwordB = true;
    } else {
      validated.passwordB = false;
    }

    setErrors(validated);
    return Object.values(validated).every((x) => x === false);
  };

  const formEntry = (e) => {
    setCustomer({
      ...customer,
      [e.target.id]: e.target.value,
    });
  };

  const cancelRegistration = () => {
    setRegistrationStatus("Complete");
    resetForm();
  };

  const registerUser = async () => {
    setShowBackdrop(true);
    if (validation()) {
      await axios
        .post(`/api/registration`, customer)
        .then((response) => {
          if (response.data === "Success") {
            setAlert(true, "Registration Successful", "success");
            setRegistrationStatus("Complete");
            resetForm();
          } else if (response.data === "Duplicate User") {
            setAlert(
              true,
              "Email already exist - reset password instead!",
              "error"
            );
            setTimeout(() => setRegistrationStatus("Duplicate"), 5000);
            resetForm();
          }
        })
        .catch((error) => {
          setAlert(true, "Registration Failed", "error");
        });
    } else {
      setShowBackdrop(false);
      setAlert(true, "Fix validation errror(s)", "error");
    }
  };

  return (
    <Container>
      {showBackdrop && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={showBackdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {registrationStatus === "In Progress" && (
        <Card elevation={4} sx={{ mt: 3, p: { xs: 1, sm: 2 } }}>
          <CardContent>
            <Typography
              variant="h5"
              sx={{
                textAlign: "center",
                fontSize: { xs: "1.35rem", sm: "1.5rem" },
              }}
              color="primary"
            >
              Bill Pay Registration Form
            </Typography>
            <Card sx={{ mt: 2, p: 2 }} elevation={2}>
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="standard"
                    label="AB&T Account #"
                    id="abtAccountNumber"
                    onChange={formEntry}
                    value={customer.abtAccountNumber}
                    sx={{ minWidth: "90%" }}
                    error={errors.abtAccountNumber}
                    helperText={
                      errors.abtAccountNumber && helperText.abtAccountNumber
                    }
                  />
                </Grid>
              </Grid>
            </Card>
            <Grid container sx={{ mt: 3 }}>
              <Grid item xs={12} sm={6}>
                <Card
                  sx={{
                    padding: { xs: "16px", sm: "32px 32px 48px 32px" },
                    maxWidth: { xs: "100%", sm: "90%" },
                  }}
                  elevation={2}
                >
                  <Grid container>
                    <Grid item xs={12}>
                      <TextField
                        variant="standard"
                        label="Company"
                        id="company"
                        onChange={formEntry}
                        value={customer.company}
                        sx={{ minWidth: "100%" }}
                        error={errors.company}
                        helperText={errors.company && helperText.company}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant="standard"
                        label="First Name"
                        id="firstName"
                        onChange={formEntry}
                        value={customer.firstName}
                        sx={{ minWidth: "48%" }}
                        error={errors.firstName}
                        helperText={errors.firstName && helperText.firstName}
                      />
                      <TextField
                        variant="standard"
                        label="Last Name"
                        id="lastName"
                        onChange={formEntry}
                        value={customer.lastName}
                        sx={{ minWidth: "50%", ml: 1 }}
                        error={errors.lastName}
                        helperText={errors.lastName && helperText.lastName}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant="standard"
                        label="Address"
                        id="address"
                        onChange={formEntry}
                        value={customer.address}
                        sx={{ minWidth: "100%" }}
                        error={errors.address}
                        helperText={errors.address && helperText.address}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant="standard"
                        label="City"
                        id="city"
                        onChange={formEntry}
                        value={customer.city}
                        sx={{ minWidth: "100%" }}
                        error={errors.city}
                        helperText={errors.city && helperText.city}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant="standard"
                        label="State"
                        id="state"
                        onChange={formEntry}
                        value={customer.state}
                        sx={{ minWidth: "100%" }}
                        error={errors.state}
                        helperText={errors.state && helperText.state}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant="standard"
                        label="Zip"
                        id="zip"
                        onChange={formEntry}
                        value={customer.zip}
                        sx={{ minWidth: "100%" }}
                        error={errors.zip}
                        helperText={errors.zip && helperText.zip}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant="standard"
                        label="Phone"
                        id="phoneNumber"
                        onChange={formEntry}
                        value={customer.phoneNumber}
                        sx={{ minWidth: "100%" }}
                        error={errors.phoneNumber}
                        helperText={errors.phone && helperText.phoneNumber}
                      />
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Card
                  sx={{
                    padding: { xs: "8px", sm: "16px 32px 35px 32px" },
                    maxWidth: "100%",
                    mt: 2,
                  }}
                  elevation={2}
                >
                  <Grid container>
                    <Grid item xs={12}>
                      <TextField
                        variant="standard"
                        label="Email Address (UserName)"
                        id="email"
                        onChange={formEntry}
                        value={customer.email}
                        sx={{ minWidth: { xs: "80%", sm: "100%" } }}
                        error={errors.email}
                        helperText={errors.email && helperText.email}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant="standard"
                        type="password"
                        label="Password"
                        id="password"
                        onChange={formEntry}
                        value={customer.password}
                        sx={{ minWidth: { xs: "80%", sm: "90%" }, ml: 1 }}
                        error={errors.password}
                        helperText={errors.password && helperText.password}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant="standard"
                        label="Re-Enter Password"
                        type="password"
                        id="passwordB"
                        onChange={formEntry}
                        value={customer.passwordB}
                        sx={{ minWidth: { xs: "80%", sm: "90%" }, ml: 1 }}
                        error={errors.passwordB}
                        helperText={errors.passwordB && helperText.passwordB}
                      />
                    </Grid>
                  </Grid>
                  <Typography
                    variant="body2"
                    color="primary"
                    sx={{ mt: 2, padding: "0 8px", fontWeight: "bold" }}
                  >
                    Password must be atleast 8 characters with a capital,
                    lowercase, number, and special character
                  </Typography>
                </Card>
              </Grid>
            </Grid>

            <Grid container sx={{ mt: 3 }}>
              <Grid item xs={12}>
                <Button variant="contained" color="error" onClick={resetForm}>
                  RESET
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ float: "right" }}
                  onClick={registerUser}
                >
                  REGISTER
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  sx={{ float: "right", mr: 1 }}
                  onClick={cancelRegistration}
                >
                  CANCEL
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}

      {registrationStatus === "Complete" && <Redirect push to="/login" />}
      {registrationStatus === "Duplicate" && (
        <Redirect push to="/passwordReset" />
      )}
      <NewAlert />
    </Container>
  );
};

export default Registration;

import React, { useState } from "react";
import CreatePaymentMethod from "./CreatePaymentMethod";
import EditPaymentMethod from "./EditPaymentMethod";
import MethodsList from "./MethodsList";

const PaymentMethods = ({ setPortalPage }) => {
  const [loadedPage, setLoadedPage] = useState("list");
  const [focusedMethod, setFocusedMethod] = useState(null);

  switch (loadedPage) {
    default:
    case "list":
      return (
        <MethodsList
          setLoadedPage={setLoadedPage}
          setFocusedMethod={setFocusedMethod}
          focusedMethod={focusedMethod}
          setPortalPage={setPortalPage}
        />
      );
    case "create":
      return <CreatePaymentMethod setLoadedPage={setLoadedPage} />;
    case "edit":
      return (
        <EditPaymentMethod
          setLoadedPage={setLoadedPage}
          setFocusedMethod={setFocusedMethod}
          focusedMethod={focusedMethod}
        />
      );
  }
};

export default PaymentMethods;
